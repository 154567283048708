import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import { Icon, Card, IconButton, Tooltip } from "@mui/material";
import CapsuleText from "components/CapsuleText";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import PageHeader from "components/PageHeader";
import AnimatedRoute from "components/AnimatedRoute";
import fetchRequest from "utils/fetchRequest";
import useHandleError from "hooks/useHandleError";
import { useYADialog } from 'components/YADialog';

const buildColumns = () => {
    return [
        {
            "Header": "Actions",
            "disableSorting": true,
            "accessor": "actions",
            "maxWidth": 40,
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Cube",
            "accessor": "cube",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Cube Name",
            "accessor": "cubeName",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refresh Condition",
            "accessor": "details",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        },
        {
            "Header": "Refreshed Cube(s)",
            "accessor": "cubeList",
            "Cell": ({ cell: { value } }) => {
                return value.map( v => <MDTypography key={v} variant="caption" color="dark">
                    [{v.replace("WithBudget","").replace("Details","").replace("WithForecasts","").replace("WithBusinessUnit","")}]&nbsp;
                </MDTypography>)
            }
        },
        {
            "Header": "Last Refreshed",
            "accessor": "lastRefreshed",
            "Cell": ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">
                    {value}
                </MDTypography>
            }
        }
    ]
}

const buildRows = (data, setRefresh, showSnackbar) => {

    const onRefresh = async (cubeKey) => {
        let cubeVal = cubeKey.charAt(0).toLowerCase() + cubeKey.slice(1);
        var [err, data] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}?force=true`);
        if (err) {
            console.err(err)
        }
        else {
         setRefresh(Math.random());
         showSnackbar(data, "success")
         }
        }


    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, "__")] = r[k]
            });
            row["actions"] = (
                <MDBox display="flex"  alignItems="center" mt={{ xs: 2, sm: 0 }}>
                    <Tooltip title="Refresh" placement="top">
                        <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onRefresh(r["cube"])}>
                            <Icon fontSize="small" >refresh</Icon>
                        </IconButton>
                    </Tooltip>
                    {/* <MDTypography display="flex" alignItems="center" component="a" href="#" onClick={() => onRefresh(r["cube"])} variant="caption" color="text" fontWeight="medium">
                        <Icon fontSize="small" >refresh</Icon>&nbsp;Refresh
                    </MDTypography> */}
                </MDBox>
            )
            rows.push(row);
        });
    }
    return rows;
}

const CubeRefreshKey = () => {
    const { showSnackbar } = useYADialog();
    const [refresh, setRefresh] = useState(null);
    const handleError = useHandleError();
    const [rows, setRows] = useState([]);
    const [autoRefreshStatus, setAutoRefreshStatus] = useState("Fetching")
    const columns = buildColumns();

    useEffect(() => {
        async function getList() {
            var [err, data] = await fetchRequest.get(`/api/dataflow/getCubeRefreshKey`);
            if (err) {
                handleError(err);
            }
            else {
                data = data.filter((obj) => {
                    return obj.cube !== "All";
                });
                setRows(buildRows(data, setRefresh, showSnackbar));
            }
        }
        getList();
    }, [refresh]);

    useEffect(() => {
        async function getFiancialYearDetails() {
            var [, data] = await fetchRequest.get(`/api/settings/category/financialYearSetup`);
            if (data) {
                let refreshStatus = data.find(item => item.name === "autoCubeRefresh")?.value
                if (refreshStatus === "true") {
                    setAutoRefreshStatus("ON")
                } else
                    setAutoRefreshStatus("OFF")
            }
        }
        getFiancialYearDetails()
    }, [])

    const onRefreshAll = async (cubeKey) => {
        let cubeVal = cubeKey.charAt(0).toLowerCase() + cubeKey.slice(1);
        var [err, data] = await fetchRequest.post(`/api/dataflow/cubeRefreshKey/${cubeVal}?force=true`);
        if (err) {
            console.log(err)
        }
        else {
             setRefresh(Math.random());
            showSnackbar(data, "success")
              }
             }

    return (
        <>
            <MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row" justifyContent="space-between">
                <PageHeader title="Cube Refresh" subtitle="Manually refresh cubes on an ad hoc basis, or in cases where an automatic cube refresh has not occurred." />
                <MDBox
                    mt={2.5}
                    mr={3}
                    pt={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <CapsuleText leftSideText={"Auto Cube Refresh "} rightSideText={autoRefreshStatus} />
                    <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => onRefreshAll("all")}
                    >
                        Refresh All
                    </MDButton>
                </MDBox>

            </MDBox>
            <MDBox p={3} pt={1}>
                <Card sx={{ height: "100%" }} px={0}>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    />
                </Card>
            </MDBox>

        </>
    );
};

export default AnimatedRoute(CubeRefreshKey);



