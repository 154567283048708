import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import { useImmer } from "use-immer";
import { normalizeCurrency } from "utils/table";
import MDAvatar from "components/MDAvatar";
import moment from "moment";


const OfferingMappingView = (props) => {
    const { yearFilter, monthFilter, containerHeight, } = props;
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState(draft => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    }
    const { response: uploadedFiles, error: _err, loading: loading } =  useFetchRequest(`/api/dataflow/solution/${yearFilter}/${monthFilter}`)

  
    

    let columns = [
            { Header: "Asset Type", accessor: "assetType", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</MDTypography> } },
            { Header: "Asset Code", accessor: "capabilityCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Asset Name", accessor: "applicationName", dataType: "textbox", disableFilters: false,  Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value}</MDTypography> } },
            { Header: "Offering Code", accessor: "offeringCode", dataType: "textbox", disableFilters: true,  Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Offering Name", accessor: "offeringName", dataType: "textbox", disableFilters: true,  Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "rPortion", dataType: "textbox", disableFilters: false,  Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value.toFixed(2)}</MDTypography> } },
            { Header: "Amount", accessor: "spend", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>} },
            { Header: "Source", accessor: "source", dataType: "textbox", disableFilters: false,  Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{(value) ? "Rule": "File"}</MDTypography> } },
            { Header: "Created On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Created By", accessor: "name", disableFilters: true, Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>} }},

        ]
    if (loading) return <YASkeleton variant="loading" />

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                no data
            </div>
        );
    }
    if (_err)
        console.error(_err)
    const rows = uploadedFiles.map((row) => {
        let r = {};
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });

    return (
        <>
            <DataTable
                variant="tile"
                table={{ columns, rows }}
                containerMaxHeight={containerHeight}
                
                isSorted={true}
                
                entriesPerPage={true}
                canSearch={true}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
                
            >
            </DataTable>
        </>
    )
}

export default OfferingMappingView;