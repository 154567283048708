import { Card, Modal, Tab, Tabs, Icon, IconButton } from "@mui/material";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import { useCallback, useMemo, useState } from "react";
import numeral from "numeral";
import fetchRequest from "utils/fetchRequest";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import _ from "lodash";
import SingleBURule from "./components/SingleBURule";
import MultiBURule from "./components/MultiBURule";
import moment from 'moment';
import { useAppController } from "context";
import { useImmer } from "use-immer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StrategySelectionSidebar from "pages/Dataflow/components/StrategySelectionSidebar";
import * as XLSX from "xlsx";
import { normalizeCurrency } from "utils/table";
import { convertPlural } from "utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ height: "100%", width: "100%" }}
    >
      {value === index && (
        <MDBox p={3} height="100%" width="100%" {...other}>
          {children}
        </MDBox>
      )}
    </div>
  );
}
const AddBURule = (props) => {
  const { typeFilter, yearFilter, monthFilter, setRefresh, chargeBackMonthly} = props;
  const [mappingType, setMappingType] = useState(typeFilter === "Spend" ? 1 : 1)
  const chargeBack = _.find(chargeBackMonthly , { monthNameId : monthFilter, yearNameId: yearFilter}) ? true : false 

  let mappingTypes = []

  if (typeFilter === "Spend")
  {  
    mappingTypes = [
      { value: 1, displayName: "Solution Offering" },
    ]
  }
  else{
    mappingTypes = [
      { value: 2, displayName: "Solution Offering" },
    ]
  }

  const selectedMappingTypeOption = mappingTypes.find(o => o.value === mappingType);

  const handleChangeMappingType = (value) => {
    setMappingType(value);
  }

  const strategyItemStyles = ({ palette: { white, info } }) => ({
    display: "flex",
    flexDirection: "column",
    px: 2,
    py: 1.5,
    m: 0.8,
    zIndex: 2,
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .title": {
      marginBottom: 1
    },
    "&:hover": {
      backgroundColor: info.main
    },
    "&:hover .title, &:hover .subtitle": {
      color: white.main,
    }
  });

  if (typeFilter === "Spend")  
    return (
      <>
        {
          !mappingType && (
            <MDBox height="100%" px={3} pt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <MDTypography variant="subtitle1" fontWeight="light" color="text" component="span" mb={3} mt={props.mt}>
                Choose a mapping strategy
              </MDTypography>
              <MDBox display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" px={3}>
                {
                  mappingTypes?.map((option) => {
                    return <MDBox key={`l_${option.value}`}
                      sx={(theme) => strategyItemStyles(theme)}
                      onClick={() => {
                        setMappingType(option.value)
                      }}
                    >
                      <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                      <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{option.displayName}</MDTypography>
                    </MDBox>
                  })
                }
              </MDBox>


            </MDBox>
          )
        }
        {
          mappingType && <FetchData {...props} chargeBack={chargeBack} mappingTypes={mappingTypes} mappingType={mappingType} onChangeMappingType={handleChangeMappingType} selectedMappingTypeOption={selectedMappingTypeOption}  typeFilter={typeFilter} setRefresh={setRefresh} />
        }
      </>
    )
  else 
    return (
      <>
        {
          mappingType !== undefined && <FetchData {...props} chargeBack={chargeBack} yearFilter={yearFilter} monthFilter={monthFilter} mappingTypes={mappingTypes} mappingType={2} typeFilter={typeFilter} setRefresh={setRefresh}/>
        } 
     </>
   )          
}

const FetchData = (props) => {
  const { yearFilter, monthFilter,typeFilter ,setRefresh, chargeBack } = props;
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }

  let url
  if (typeFilter === "Spend") {
    url = "/api/dataflow/businessunitRules"
  }
  else
    url = "/api/dataflow/businessunitRulesBudget"    
  const { response: data, error: _err, loading: loading, reloadData } = useFetchRequest(`${url}/${yearFilter}/${monthFilter}`);

  if (loading === false && data === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  if (_err)
    console.error(_err)
  return (
    <MDBox>
      {
        loading && <YASkeleton variant="loading" />
      }
      {
        !loading && <ShowData {...props} data={data} chargeBack={chargeBack} reloadData={reloadData} filtersState={filtersState} handleOnFiltersStateUpdate={handleOnFiltersStateUpdate} setRefresh={setRefresh}/>
      }
    </MDBox>
  )
}

const ShowData = (props) => {
  const { data, yearFilter, monthFilter, reloadData, mappingType, onChangeMappingType, mappingTypes, selectedMappingTypeOption, filtersState, handleOnFiltersStateUpdate, containerHeight, typeFilter, chargeBack } = props;
  const [showOptions, setShowOptions] = useState(false);
  const { showSnackbar } = useYADialog();
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [glRows, setGLRows] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [controller,] = useAppController();
  const { appDef: { featureSet, settings } } = controller;
  const enableAssetDistribution = featureSet && featureSet.dataManagement?.assetDistribution === true;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  let costElementsRemaining = []
  const handleOnOptionsClick = () => {
    setShowOptions(true);
  }

  const handleOnOptionsClose = () => {
    setShowOptions(false);
  }

  const handleOnOptionSelection = (value) => {
    if (onChangeMappingType)
      onChangeMappingType(value);
  }

  const handleOnUpdate = useCallback(({ selected }) => {
    setSelectedRows(selected)
  }, [yearFilter, monthFilter])

  const handleOnSelectionClearClick = () => {
    setSelectedRows([])
    setRefresh(Math.random())
  }

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
  }

  const handleDialogOpen = () => {
    setDialogOpen(false)
  }

  const getDetails = async (e, row) => {
    if (costElementsRemaining.length > 0 && e.target.innerHTML !== "") {
      row.original.mappingType = mappingType;
      row.original.year = yearFilter;
      row.original.month = monthFilter;
      row.original.mapping = 'tower';

      let [err, data] = await fetchRequest.post(`/api/dataflow/getGLData/`, JSON.stringify(row.original));
      if (err) {
        console.error('err', err)
        // handleError(err);
      }
      else {
        let newData = data.map(item => {
          return {
            "accountCode": item["account.code"],
            "accountDescription": item["account.description"],
            "expenseType": item["expenseType.name"],
            "costCentreCode": item["costCentre.code"],
            "costCentreDescription": item["costCentre.description"],
            "vendorCode": item["vendor.code"],
            "vendorName": item["vendor.name"],
            "rgtModel": item["rgtModel"],
            "costType": item["costType"],
            "amount": item["amount"],
            "applicationID": item["applicationID"],
            "invoice": item["invoice"],
            "journalID": item["journalID"],
            "journalLine": item["journalLine"],
            "projectID": item["projectID"],
            "transactionDate": item["transactionDate"]
          }
        });
        setGLRows(newData)
        setDialogOpen(true)
      }
    }
  };
  const getPresignedUrl = async (fId) => {
    return await fetchRequest.get(`/api/blob/presignedPost/${fId}`);
  }
  const submitRules = async (ceSelected, fileName, fileData, mappedFields) => {
    setIsSubmitting(true);
    if (fileName && fileName !== "") {
      var uf = {}
      uf.originalFileName = fileName
      uf.mappingFields = JSON.stringify(mappedFields)
      uf.yearNameId = yearFilter
      uf.monthNameId = monthFilter
      uf.totalRecords = fileData.length
      uf.loadedRecords = fileData.length
      uf.destinationTable = "BUConsumption"
      uf.fileStatus = "Loaded"

      const [resError, response] = await fetchRequest.post(`/api/dataflow/createupload`, uf);
      if (resError) {
        console.error("An error occured while creating upload");
        console.error(resError);
        if (resError.data?.message) {
          showSnackbar(resError.data?.message, "error")
          setRefresh(Math.random());
          reloadData()
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        return false;
      }

      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(fileData);
      XLSX.utils.book_append_sheet(wb, ws1);

      var data = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      var newFile = new File([data], fileName);

      const [presignedUrlError, presignedUrlResponse] = await getPresignedUrl(response.id);
      if (presignedUrlError) {
        console.error("An error occured while getting presigned url");
        console.error(presignedUrlError);
        if (presignedUrlError.data?.message) {
          showSnackbar(presignedUrlError.data?.message, "error")
          setRefresh(Math.random());
          reloadData()
        } else {
          showSnackbar("An error occured while processing your request.", "error");
        }
        return false;
      }

      const options = new URL(presignedUrlResponse).host.indexOf("blob.core.windows.net") !== -1 ? {
          headers: {
              'X-Ms-Blob-Type': 'BlockBlob',
              'X-Ms-Version': '2023-11-03'
          }
      } : null;
      const [putError,] = await fetchRequest.put(presignedUrlResponse, newFile, options);
      if (putError) {
        console.error("An error occured while uploading to blob store");
        console.error(putError);
        showSnackbar("An error occured while processing your request.", "error");
        return false;
      }
      ceSelected.map((a) => a["allocationFileId"] = response.id)
    }
    ceSelected.map((a) => a.destinationTable = convertPlural(a.destinationTable))
    let [err, data1] = await fetchRequest.post(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}`, JSON.stringify(ceSelected))
    if (err) {
      console.error(err);
      if (err.data?.message) {
        showSnackbar(err.data?.message, "error")
        setRefresh(Math.random());
        reloadData()
      } else {
        showSnackbar("An error occured while processing your request.", "error");
      }
    }
    else if (data1) {
      showSnackbar(data1, "success");
       if(props.setRefresh)
       {
        props.setRefresh(Math.random());
       } 
      reloadData();
    }
    setIsSubmitting(false);
  }

  costElementsRemaining = data.costElements.map((a, i) => { a.id = i; return a})
  //   if (mappingType === 0) {
  //   }
  // if (mappingType === 1) {
  //   costElementsRemaining = data.costElements.map((a, i) => { return ({ id: i, costCentreId: a["costCentre.id"], CostCentreCode: a["costCentre.code"], CostCentreDescription: a["costCentre.description"], expenseTypeId: a["expenseType.id"], ExpenseType: a["expenseType.name"], amount: a.amount, costPoolId: a["costPoolMappings.costPool.id"], subCostPoolId: a["costPoolMappings.subCostPool.id"], costPoolName: a["costPoolMappings.costPool.name"], subCostPoolName: a["costPoolMappings.subCostPool.name"] }) })
  // }
  // if (mappingType === 2) {
  //   costElementsRemaining = data.costElements.map((a, i) => { return ({ id: i, costCentreId: a["costCentre.id"], CostCentreCode: a["costCentre.code"], CostCentreDescription: a["costCentre.description"], expenseTypeId: a["expenseType.id"], ExpenseType: a["expenseType.name"], accountId: a["account.id"], AccountCode: a["account.code"], AccountDescription: a["account.description"], vendorId: a["vendor.id"], VendorCode: a["vendor.code"], VendorName: a["vendor.name"], amount: a.amount, costPoolId: a["costPoolMappings.costPool.id"], subCostPoolId: a["costPoolMappings.subCostPool.id"], costPoolName: a["costPoolMappings.costPool.name"], subCostPoolName: a["costPoolMappings.subCostPool.name"] }) })
  // }
  
  const amountAllocated = selectedRows?.reduce((total, idx) => total + costElementsRemaining[costElementsRemaining.findIndex(i => i.id === idx)]?.amount, 0);

  let columns = [
      { Header: "Solution Offering Code", accessor: "code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Solution Offering Name", accessor: "name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Unit Of Measure", accessor: "unitOfMeasure", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Amount", accessor: "amount", align: "right", dataType: "currency", disableFilters: false, Cell: ({ cell: { value } }) => {return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>} },
    ]

  const columnsMemo = useMemo(() => columns, [yearFilter, monthFilter]);
  const rowsMemo = useMemo(() => costElementsRemaining, [yearFilter, monthFilter, refresh]);

  const filteredMappingTypes = mappingTypes.filter(m => m.value !== mappingType);

  const strategyItemStyles = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pl: 2,
    pr: 1,
    py: 1.5,
    zIndex: 2,
    marginBottom: "0px",
    marginRight: "-10px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .selectionBox": {
      display: "flex",
      flexDirection: "column",
    },
    "& .title": {
      marginBottom: 1
    }
  });

  let glColumns = [
    { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Account Description", accessor: "accountDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Solution Offering Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Solution Offering Name", accessor: "costCentreDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> } },
    { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
    { Header: "Invoice Number", accessor: "invoice", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
  ]

  if (costElementsRemaining.length > 0) {
    return (
      <>
        <Modal open={dialogOpen} onClose={handleDialogOpen}>
          <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
            <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
              <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDBox>
                  <MDTypography variant="h6" component="span" color="text">
                    General Ledger Transactions
                  </MDTypography>
                </MDBox>
                <MDBox display="flex">
                  <IconButton onClick={handleDialogOpen} title="Close">
                    <Icon>close</Icon>
                  </IconButton>
                </MDBox>
              </MDBox>
              <DataTable
                variant="tile"
                table={{ columns: glColumns, rows: glRows }}
                containerMaxHeight={424}
                showTotalEntries={true}
                isSorted={true}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
              >
              </DataTable>
            </Card>
          </MDBox>
        </Modal>
        <MDBox display="flex" flexDirection="row">
          <MDBox width={selectedRows?.length > 0 ? "50%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
            <MDBox pl={3} pr={4} pt={1} display="flex" alignItems="center" justifyContent="space-between">
              <MDTypography variant="subtitle1" fontWeight="medium" color="dark" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">Select one or more Solution Offerings to create a rule.</MDTypography>
              {
                selectedRows?.length === 0 &&
                <MDBox
                  sx={(theme) => strategyItemStyles(theme)}
                  onClick={handleOnOptionsClick}
                >
                  <MDBox className="selectionBox">
                    <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                    <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{selectedMappingTypeOption?.displayName}</MDTypography>
                  </MDBox>
                  <Icon sx={{ ml: 1.5, mt: .5, fontSize: "32px!important", color: "rgba(0, 0, 0, 0.54)" }}>keyboard_arrow_down</Icon>
                </MDBox>
              }
            </MDBox>
            <DataTable
              variant="tile"
              table={{ columns: columnsMemo, rows: rowsMemo }}
              showTotalEntries={true}
              containerMaxHeight={containerHeight}
              isSorted={true}
              newStyle1={true}
              noEndBorder
              entriesPerPage={true}
              canSearch={true}
              isSelectable={typeFilter === "Spend" && !chargeBack ? true : false}
              onUpdate={handleOnUpdate}
              onRowClick={getDetails}
              onSelectionClearClick={handleOnSelectionClearClick}
              filtersState={filtersState}
              onFiltersStateUpdate={handleOnFiltersStateUpdate}
              canFilter={true}
            >
            </DataTable>
          </MDBox>
          {
            selectedRows?.length > 0 && (
              <MDBox width="50%" pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
                <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <MDBox flex={1} textAlign="center" display="flex">
                    <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
                      <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
                      <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(amountAllocated.toFixed(2)).format('$0,0')}</MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <Icon sx={{ mt: 1, mb: 1, color: "#7b809a", fontSize: "28px!important" }}>south</Icon>
                  </MDBox>
                </MDBox>
                <MDBox
                  height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
                  <Tabs width="100%" value={tabValue} onChange={handleChange} textColor="inherit">
                    <Tab disableRipple label="Business Unit" />
                    <Tab disableRipple label="Multiple Business Unit" />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <SingleBURule enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={costElementsRemaining} selectedRows={selectedRows} isSubmitting={isSubmitting} submitRules={submitRules} businessUnits={data.businessUnits} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1} display="flex" flexDirection="column" alignItems="center" px={0} py={0}>
                    <MultiBURule enableAssetDistribution={enableAssetDistribution} yearFilter={yearFilter} monthFilter={monthFilter} costElementsRemaining={costElementsRemaining} selectedRows={selectedRows} isSubmitting={isSubmitting} submitRules={submitRules} businessUnits={data.businessUnits} />
                  </TabPanel>
                </MDBox>
              </MDBox>
            )
          }
          {showOptions && (
            <StrategySelectionSidebar
              options={filteredMappingTypes}
              onOptionSelection={handleOnOptionSelection}
              onOptionsClose={handleOnOptionsClose}
            />
          )}
        </MDBox>
      </>
    )
  } else if (costElementsRemaining.length === 0 && data.noOfItems > 0) {
    return (<MDBox height="100%" display="flex" alignItems="center" justifyContent="center">
      <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br/><br/>All Solution Offerings have been mapped successfully.</MDTypography>
    </MDBox>
    );
  } else if (data.noOfItems === 0) {
    return (<MDBox height="100%" display="flex" alignItems="center" justifyContent="center">
      <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br/><br/>No Solution Offerings to Map.</MDTypography>
    </MDBox>
    );
  }
}

export default AddBURule;