import { Icon, IconButton, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import { useAppController } from "context";
import { useImmer } from "use-immer";
// import { useState, useEffect } from "react";
// import MDBox from "components/MDBox";
import { normalizeCurrency } from "utils/table";
import _ from "lodash";


const BUMappingView = (props) => {
    // const { showAlert, showPrompt, showSnackbar, showCustomForm} = useYADialog();
    const { showAlert, showPrompt, showSnackbar} = useYADialog();
    // const { yearFilter, monthFilter, id, ruleName, containerHeight, typeFilter, overallocation, setRefresh, chargeBackMonthly } = props;
    const { yearFilter, monthFilter, id, ruleName, containerHeight, overallocation, setRefresh, chargeBackMonthly } = props;
    const [controller,] = useAppController();
    // const { appDef: { featureSet, settings } } = controller;
    const { appDef: { featureSet } } = controller;
    const enableAssetDistribution = featureSet && featureSet.dataManagement?.assetDistribution === true;
    // const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
    const chargeBack = _.find(chargeBackMonthly , { monthNameId : monthFilter, yearNameId: yearFilter}) ? true : false 
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState(draft => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    }
    const { response: uploadedFiles, error: _err, loading: loading, reloadData } = (id) ? useFetchRequest(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}?towerId=${id}`)
        : ((ruleName) ? useFetchRequest(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}?ruleName=${ruleName}`)
         : overallocation ? useFetchRequest(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}?overallocation=${overallocation}`) : useFetchRequest(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}`))

    const handleDelete = (item) => {
        showPrompt("Delete", "Are you sure you want to delete all Rules on - [" + item["solutionOfferingsName"] + "]", async () => {
            let [err, data] = await fetchRequest.delete(`/api/dataflow/businessunitRules/${yearFilter}/${monthFilter}/${item['solutionOfferingsCode']}`)
            if (err) {
                if (err.data?.message) {
                    showSnackbar(err.data?.message, "error")
                    setRefresh(Math.random());
                    reloadData()
                  } else {
                    showAlert("Delete", "Something went wrong. Contact your administrator.");
                  }
            }
            else if (data) {
                showSnackbar(data, "success")
               if(setRefresh)
               {
                setRefresh(Math.random())
               }
                reloadData()
            }
        });
    }

    const handleDeleteMultiple = (selectedRows) => {        
        let uniqueSelectedRows = [...new Set(selectedRows)]
        showPrompt('Delete', 'Are you sure you want to delete?', () => deleteBusinessUnitMappingMultipleRecords(JSON.stringify(uniqueSelectedRows)));
    };

    const deleteBusinessUnitMappingMultipleRecords = async (selectedRows) => {
        const [err, data] = await fetchRequest.post(`/api/dataflow/businessunitRules/${yearFilter}/${monthFilter}`, selectedRows);

        if (err) {
            if (err.data?.message) {
                showSnackbar(err.data?.message, "error")
                setRefresh(Math.random());
                reloadData()
              } else {
                console.error(err)
                showAlert("Delete", "Something went wrong. Contact your administrator.");
              }
        }
        else
            if (data) {
                showSnackbar(data, "success")
                if(setRefresh)
                {
                setRefresh(Math.random())
                }
                reloadData()
            }
    }
    
    let columns = [
            { Header: "Delete", disableSorting: true, disableFilters: true, hidden: chargeBack, accessor: "id", maxWidth: 70, Cell: ({ row }) => { return <IconButton sx={{ padding: 0 }} onClick={() => handleDelete(row.original)}><Icon color="error">delete</Icon></IconButton> } },
            { Header: "Solution Offering", accessor: "solutionOfferingsName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value}</MDTypography> } },
            { Header: "Business Unit", accessor: "businessUnitsName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Amount", accessor: "exAmount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>} },
            { Header: "Usage", accessor: "usage", disableFilters: true, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Solution Amount", accessor: "glAmount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>} },
            { Header: "Rule Name", accessor: "ruleName", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            // { Header: "Tier", accessor: "tier", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Condition", accessor: "condtionstr", disableFilters: true, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip placement="top" title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null } },
            { Header: "Destination", accessor: "destinationTable", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Weight", accessor: "weight", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "File", accessor: "originalFileName", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Created On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
            { Header: "Created By", accessor: "name", disableFilters: true, Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>} }},
        ]
    if (loading) return <YASkeleton variant="loading" />

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                no data
            </div>
        );
    }
    if (_err)
        console.error(_err)
    const rows = uploadedFiles.map((row) => {
        let r = {};
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });


    // let GLPopUp = (props) => {
    //     const [glRows, setGLRows] = useState([]);
    //     if (typeFilter == "Spend") {
    //         var getDetails = async (e, row) => {
    //             if (e.target.innerHTML !== "" && e.target.innerHTML !== "delete" && rows.length > 0) {
    //                 row.original.mappingType = 1;
    //                 row.original.year = yearFilter;
    //                 row.original.month = monthFilter;
    //                 //   row.original.mapping = 'costpool';
    //                 row.original.view = true;
    //                 // console.log('row',row.original)
    //                 let [err, data] = await fetchRequest.post(`/api/dataflow/getGLData/`, JSON.stringify(row.original));
    //                 if (err) {
    //                     console.error('err', err)
    //                     // handleError(err);
    //                 }
    //                 else {
    //                     let newData = data.map(item => {
    //                         return {
    //                             "accountCode": item["account.code"],
    //                             "accountDescription": item["account.description"],
    //                             "expenseType": item["expenseType.name"],
    //                             "costCentreCode": item["costCentre.code"],
    //                             "costCentreDescription": item["costCentre.description"],
    //                             "vendorCode": item["vendor.code"],
    //                             "vendorName": item["vendor.name"],
    //                             "amount": item["amount"],
    //                             "applicationID": item["applicationID"],
    //                             "invoice": item["invoice"],
    //                             "journalID": item["journalID"],
    //                             "journalLine": item["journalLine"],
    //                             "projectID": item["projectID"],
    //                             "transactionDate": item["transactionDate"]
    //                         }
    //                     });
    //                     setGLRows(newData)
    //                 }
    //             }
    //         };
    //         useEffect(() => {
    //             getDetails(props.e, props.row)
    //         }, [props])
    //         var glColumns = [
    //             { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Account Description", accessor: "accountDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Cost Center Name", accessor: "costCentreDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => {return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>} },
    //             { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //             { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
    //             { Header: "Invoice Number", accessor: "invoice", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    //         ]
    //     }

    //     return (
    //         <>
    //         <MDBox
    //             pb={3}
    //             minWidth="800px"
    //             width="100%" >
    //             <DataTable
    //                 variant="tile"
    //                 table={{ columns: glColumns, rows: glRows }}
    //                 containerMaxHeight={424}
    //                 showTotalEntries={true}
    //                 isSorted={true}
    //                 newStyle1={true}
    //                 noEndBorder
    //                 entriesPerPage={true}
    //                 canSearch={true}
    //             ></DataTable>
    //         </MDBox>
    //     </>
    //     )
    // }

    // let getGLDetails = (e, row, columns) => {
    //     if (
    //         e.target.innerHTML !== "" &&
    //         e.target.innerHTML !== "delete" &&
    //         rows.length > 0
    //     )
    //     showCustomForm(
    //         "General Ledger Transactions",
    //         () => <GLPopUp e={e} row={row} columns={columns} />,
    //         null,
    //         null,
    //         null,
    //         '1080px'
    //     );
    // };

    // useEffect(() => {
    //     console.log(glProps.length)

    //     if(glProps.length>0) {
    //     // showCustomForm("", () => <GLPopUp e={glProps[0]} row={glProps[1]} columns={glProps[2]} onClose={handleGlclose}/>);   
    //     }
    // }, [glProps]);
    // const handleOnReportSaveClick = useCallback(
    //     () => {
    //         if (!savedReportObj || !savedReportObj.reportId)
    //             showCustomForm("Save Report", () => <SavedReportForm cubeId={cubeId} query={getQueryDetails()} config={getQueryConfig()} onClose={handleSaveReportClose} />);
    //         else
    //             saveQuery(savedReportObj.reportId)
    //     },
    //     [cubeId, savedReportObj, chartType, chartConfig, queryConfig, visualizationData]
    // );

    return (
        <>
            {/* <Modal open={dialogOpen} onClose={handleDialogOpen}>
                <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox>
                                <MDTypography variant="h6" component="span" color="text">
                                    General Ledger Transactions
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                                <IconButton onClick={handleDialogOpen} title="Close">
                                    <Icon>close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                        <DataTable
                            variant="tile"
                            table={{ columns: glColumns, rows: glRows }}
                            containerMaxHeight={424}
                            showTotalEntries={true}
                            isSorted={true}
                            newStyle1={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}

                        >
                        </DataTable>
                    </Card>
                </MDBox>
            </Modal> */}
            <DataTable
                variant="tile"
                table={{ columns, rows }}
                containerMaxHeight={containerHeight}
                showTotalEntries={true}
                isSelectable= {overallocation ? false : chargeBack ? false : true}
                isSorted={true}
                newStyle1={true}
                noEndBorder
                // onRowClick={getGLDetails}
                entriesPerPage={true}
                canSearch={true}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
                deleteMultiple={!chargeBack}
                idColumnName={'solutionOfferingsCode'}
                onDeleteMultiple={handleDeleteMultiple}
            >
            </DataTable>
        </>
    )
}

export default BUMappingView;