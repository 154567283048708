import MDTypography from 'components/MDTypography';
import numeral from "numeral";
import moment from 'moment';
import { Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import YADataTable from 'components/YADataTable_CF';
import { createRef, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { AnalyticsContext } from 'context/AnalyticsContext';
import { useAppController } from 'context';
import { toNumber } from 'utils';
import { formatCurrency } from 'utils/charts';
import { formatDecimal } from 'utils/charts';
import { normalizeCurrency_CF, pickColorNum } from 'utils/table';
import DashboardItem from 'components/DashboardItem_CF';
import { useResizeDetector } from 'react-resize-detector';
import fetchRequest from "utils/fetchRequest";
import * as XLSX from 'xlsx';
import MDAvatar from 'components/MDAvatar';
import colors from "assets/theme/base/cloudForecasts/colors";
// import useFetchRequest from 'hooks/useFetchRequest';

const getCellValue = (value) => {
    return Array.isArray(value) ? value[0] : value;
}

const getChildColumnHeaderDetails = (pivotTable, colDefTitle, col) => {
    const accessor = col.key ?
        Array.isArray(col.key) ?
            col.key.join("__").replace(/\./g, "__")
            : String(col.key).replace(/\./g, "__")
        : "";
    const header = !pivotTable ?
        (colDefTitle || col.shortTitle)
        : !col.key ? " - "
            : Array.isArray(col.shortTitle) ?
                col.shortTitle[0]
                : col.shortTitle;

    return { accessor, header };
}
function rearrangeRows(rows){
    let tempRow = {}
    let r = rows
    for (let i = 0; i < r.length; i++) {
        for (let j = 0; j < r.length; j++) {
            if(j!==r.length-1){
                if (r[j].ragVals[0]<r[j+1].ragVals[0]) {
                    tempRow=r[j]
                    r[j]=r[j+1]
                    r[j+1]=tempRow
                }
                else if (r[j].ragVals[0]==r[j+1].ragVals[0]) {
                    if (r[j].ragVals[1] < r[j+1].ragVals[1]) {
                        tempRow=r[j]
                        r[j]=r[j+1]
                        r[j+1]=tempRow
                    }
                }
            }
        }
    }
    return r
}
function finalManipulation(rows,vizOptions,mthArr){
    let vopts = vizOptions
    let manipulate = vopts.manipulateColumn
    let r = rows
    let monthsArr = vopts.rolling ? mthArr : r[0][vopts.sortColumnsUsing.replace(".","__")].split(',')
    if(vopts.manipulateColumn){
        for(let j = 0; j < r.length;j++){
            for (let i = 0; i < monthsArr.length; i++) {   
                let mth = vopts.rolling? monthsArr[i].substring(0,3):monthsArr[i]
                let replace = mth+"__"+manipulate.replace(".","__")
                let condition1 = mth+"__"+vopts.forecast.replace(".","__")
                let condition2 = mth+"__"+vopts.actual.replace(".","__")
                let condition3 = monthsArr[i]+"__"+manipulate.replace(".","__")

                if(r[j][condition2]===0 || r[j][condition2]==="0"){
                    r[j][replace] = "  "
                    r[j][mth] = "  "
                    r[j][condition3] = r[j][mth]
                }
                else if(r[j][condition1] === 0 || r[j][condition1]==="0"){
                    r[j][replace] = "(100%) $"+ Number(r[j][condition2]).toFixed(0)
                    r[j][mth] = "(100%) $"+ Number(r[j][condition2]).toFixed(0)
                    r[j][condition3] = r[j][mth]
                }
                else if(!(r[j][condition1]===0)){

                    let forecast = Number(r[j][condition1]).toFixed(0)
                    let actual = Number(r[j][condition2]).toFixed(0)
                    let variance = actual - forecast
                    let percentage = Math.abs(((variance/(forecast===0?1:forecast))*100).toFixed(0))

                    if(r[j][condition1] === r[j][condition2]){
                        r[j][replace] = "(0%) $0"
                        r[j][mth] = "(0%) $0"
                        r[j][condition3] = r[j][mth]
                    }
                    else{
                        r[j][replace] = "(" + percentage + "%) " + numeral(variance).format('$0,0')
                        r[j][mth] = "(" + percentage + "%) " + numeral(variance).format('$0,0')
                        r[j][condition3] = "(" + percentage + "%) " + numeral(variance).format('$0,0')
                    }
                }
            }
        }
    }
    if (vopts.rolling && r.length === 2 ) {
        let m = (new Date()).getMonth()
        if (m <= 5) {
            for (let i=0; i<5-m; i++) 
                r[1][monthsArr[i]+"__"+manipulate.replace(".","__")] = r[0][monthsArr[i]+"__"+manipulate.replace(".","__")]
                r.shift()
        }
        else {
            for (let i=0; i<m-5; i++)
                r[0][monthsArr[11-i]+"__"+manipulate.replace(".","__")] = r[1][monthsArr[11-i]+"__"+manipulate.replace(".","__")]
                r.pop()
        }
    }

    return r
}

const buildChildColumns = (pivotTable, defaultDateFormat, measures, columnsDef, parentColumn, parentKey) => parentColumn.children?.map(
    (col) => {
        const colDef = columnsDef?.find(c => c.name === col.key);
        if (col.children) {
            const { header, accessor } = getChildColumnHeaderDetails(pivotTable, colDef?.title, col);
            return {
                Header: header,
                accessor: `${parentKey}__${accessor}`,
                disableSorting: true,
                columns: buildChildColumns(pivotTable, defaultDateFormat, measures, columnsDef, col, `${parentKey}__${accessor}`),
                Footer: () => null,
            }
        }
        else {
            const accessor = `${parentKey}__${String(col.key).replace(/\./g, "__")}`;
            return {
                Header: colDef?.title || col.shortTitle,
                align: ["currency", "number", "decimal"].includes(colDef?.dataType) ? "right" : "left",
                accessor: accessor,
                disableSorting: true,
                Cell: ({ cell: { value } }) => {
                    if (colDef?.dataType === "currency")
                        return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "decimal")
                        return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, 0)}</MDTypography>
                    else if (colDef?.dataType === "percent")
                        return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{numeral(normalizeCurrency_CF(value/100,4)).format('0.00%')}</MDTypography>
                    else if (colDef?.dataType === "boolean")
                        return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{value ? "Yes" : "No"}</MDTypography>
                    else if (colDef?.dataType === "time")
                        return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{value ? moment(value).format(colDef?.format || defaultDateFormat) : ""}</MDTypography>
                    return <MDTypography key={accessor} variant="caption" color="dark"  fontWeight={colDef?.emphasize && "medium"}>{getCellValue(value) || "  "}</MDTypography>
                },
                Footer: info => {
                    if (!measures.includes(col?.key) || !["currency", "number", "decimal"].includes(colDef?.dataType))
                        return <span></span>
                    const total = useMemo(
                        () =>
                            info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                        [info.rows]
                    )
                    if (colDef?.dataType === "decimal")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium"  color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium"  color="dark">{formatDecimal(total, 0)}</MDTypography>
                    return <MDTypography key={"accessor"} variant="caption" fontWeight="medium"  color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
                }
            }
        }
    }
);

const buildColumns = (pivotTable, defaultDateFormat, measures, columnsDef, colDef, col) => {
    const avatarStyles = (color) => ({
        marginRight: 0.75,
        background: color ? color[0] : '#454545',
        fontWeight:'bold',
        color: color ? color[1] : '#ffffff',
      })
    if (col?.children) {
        const { header, accessor } = getChildColumnHeaderDetails(pivotTable, colDef?.title, col);
        return {
            Header: header,
            accessor: accessor,
            disableSorting: true,
            columns: buildChildColumns(pivotTable, defaultDateFormat, measures, columnsDef, col, accessor),
            Footer: () => null,
        };
    }
    else {
        const accessor = String(col ? col.key : colDef.name).replace(/\./g, "__");
        return {
            Header: (colDef?.title || col.shortTitle),
            align: ["currency", "number", "decimal"].includes(colDef?.dataType) ? "right" : "left",
            accessor: accessor,
            disableSorting: true,
            Cell: ({ cell: { value } }) => {
                if (colDef?.dataType === "currency")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "decimal")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "number")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, 0)}</MDTypography>
                else if (colDef?.dataType === "percent")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{numeral(value).format('0.00%')}</MDTypography>
                else if (colDef?.dataType === "boolean")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? "Yes" : "No"}</MDTypography>
                else if (colDef?.dataType === "time")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? moment(value).format(colDef?.format || defaultDateFormat) : ""}</MDTypography>
                else { 
                    value = getCellValue(value).trim().replaceAll("  ", "")
                    let colorval = pickColorNum(value)
                    let color = colors.avatarColors[colorval]   
                return<MDTypography key={accessor} variant="caption" display="flex" color="dark" alignItems="center" justifyContent="flex-start" fontWeight={colDef?.emphasize && "medium"}><MDAvatar variant = "circular"  name={value.toUpperCase()}  size="xs" sx={() =>  avatarStyles(color) }/>{getCellValue(value) || " - "}</MDTypography>
                }
            },
            Footer: info => {
                if (!measures.includes(String(col ? col.key : colDef.name)) || !["currency", "number", "decimal"].includes(colDef?.dataType))
                    return <span></span>
                const total = useMemo(
                    () =>
                        info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                    [info.rows]
                )
                if (colDef?.dataType === "decimal")
                    return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "number")
                    return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
            },
        };
    }
};

const buildGrandTotalsColumnsGroup = (measures, pivotConfig, columnsDef) => {
    if (pivotConfig.y?.length > 1 && measures) {
        let columns = measures?.map(m => {
            const colDef = columnsDef?.find(c => c.name === m);
            const accessor = String(m).replace(/\./g, "__");
            return {
                Header: `${colDef?.title}`,
                align: "right",
                accessor: accessor,
                disableSorting: true,
                grandTotal: true,
                Cell: ({ cell: { value } }) => {
                    if (colDef?.dataType === "currency")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "decimal")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{formatDecimal(value, 0)}</MDTypography>
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{getCellValue(value) || "  "}</MDTypography>
                },
                Footer: info => {
                    if (!["currency", "number", "decimal"].includes(colDef?.dataType))
                        return <span></span>
                    const total = useMemo(
                        () =>
                            info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                        [info.rows]
                    )
                    if (colDef?.dataType === "decimal")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                    return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
                },
            }
        });

        let columnsGroup = {
            Header: "Grand Total",
            accessor: "grandTotal",
            columns: []
        };

        let currentColumnRef = columnsGroup;
        if (pivotConfig.y.length > 2) {
            pivotConfig.y?.forEach((d, i) => {
                if (i > 0 && i < pivotConfig.y.length - 1) {
                    let currentColumn = {
                        Header: "",
                        accessor: d,
                        columns: [],
                        grandTotal: true,
                    };

                    currentColumnRef.columns.push(currentColumn);
                    currentColumnRef = currentColumn;
                }
            });
        }

        currentColumnRef.columns = columns;

        return columnsGroup;
    }
    return null;
}

const PivotTableRenderer = ({title, subtitle, chartHelpContextKey, pivotTable, resultSet, vizOptions, fluidLayout }) => {

    const chartRef = createRef();
    const { ref: rref } = useResizeDetector();
    let avatar = vizOptions.avatar
    let pivotConfig = vizOptions.pivotConfig
    let download = vizOptions.download ? true : false
    const [controller,] = useAppController();
    const { appDef: { settings } } = controller;
    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const tableRef = useRef(null);
    const analyticsContext = useContext(AnalyticsContext);
    const { columns: columnsDef, config } = vizOptions;
    const [ragThresholds, setRagThresholds] = useState ([9.99, 20])
    const [mthArr, setMthArr] =useState ({})

    useEffect(() => {
        if (analyticsContext)
            analyticsContext.vizRef.current = tableRef.current;
    }, [tableRef])

    useEffect(() => {
        getSettingsValues();
        getRollingMonths();
    }, [])

    const getSettingsValues = async () => {
        const [err, data] = await fetchRequest.get(`/api/forecastInput/category`);
        if (err) {
            console.log(err);
        } else {
            let ragThresholdHigh = data.filter(
                (elem) => elem.name === 'ragThresholdGreen'
            );
            let ragThresholdLow = data.filter(
                (elem) => elem.name === 'ragThresholdAmber'
            );
            let threshold = ragThresholds.slice();

            if (ragThresholdHigh.length > 0) {
                threshold[0] = Number(ragThresholdHigh[0].value);
            }
            if (ragThresholdLow.length > 0) {
                threshold[1] = Number(ragThresholdLow[0].value);
            }
            setRagThresholds(threshold);
        }
    };

    const getRollingMonths = async () => {
        const [ err, response ] = vizOptions?.rolling ? await fetchRequest.get(`/api/forecastInput/forecastRolling/${sessionStorage["yearId"]}?teamName=${sessionStorage["Forecast Team"]}`) : "";
        // const { response } = vizOptions?.rolling ? useFetchRequest(`/api/forecastInput/forecastRolling/1?teamName=${sessionStorage["Forecast Team"]}`) : "";
        if (err) 
            console.log(err);
        else 
            setMthArr(response?.map(i => i.name));
    };

    if (!resultSet) {
        return (
            <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Icon sx={{ color: "#d0cdcd", fontSize: "64px!important" }}>leaderboard</Icon>
                <MDTypography variant="subtitle2" color="text">No Data</MDTypography>
            </MDBox>
        );
    }

    const rowCount = resultSet.tablePivot()?.length || 0;
    const isPivotTable = pivotTable && rowCount > 0;
    const pivotColumns = resultSet.tableColumns(isPivotTable ? pivotConfig : undefined);
    const rows = resultSet.tablePivot(isPivotTable ? pivotConfig : undefined).map((row) => {
        let r = {ragVals:[0,0]};

        if (isPivotTable && resultSet.loadResponse?.pivotQuery?.measures) {
            resultSet.loadResponse?.pivotQuery?.measures?.map(measureName => {
                let measureVal = Object.keys(row).filter(k => k.endsWith(measureName)).reduce((sum, k) => toNumber(row[k] || 0) + sum, 0);
                r[measureName.replace(/,/g, "__").replace(/\./g, "__")] = measureVal;
            })
        }

        Object.keys(row).forEach((c) => {
            r[c.replace(/,/g, "__").replace(/\./g, "__")] = row[c];
            r[c.slice(0,3)] = row[c]
        });
        Object.keys(r).forEach((a)=>{
                if (a.replace(/__/g,'.').includes(vizOptions.manipulateColumn) && typeof r[a]=="string" ) {
                    let monthCheck = a.slice(0,3)+"__"+vizOptions.actual.replace(/\./g, "__")
                    if(toNumber(r[monthCheck])>0){
                        if(r[a]>ragThresholds[1]){
                            r.ragVals[0]=r.ragVals[0]+1
                        }
                        else if(r[a]>ragThresholds[0])
                            r.ragVals[1]=r.ragVals[1]+1
                   }
                }
            });
        return r;
    });

    var tableColumns
    if (vizOptions.sortColumnsUsing && rows.length > 0) { 
        tableColumns = []
        let sArr = []
        let sortArr = vizOptions.rolling ? mthArr : rows[0][vizOptions.sortColumnsUsing.replace(".","__")].split(',')
        for (var i = 0; i < sortArr.length; i++)
            sArr.push(sortArr[i].substring(0,3))
        var val1, val2, key1, key2
        if (vizOptions.rolling) {
            for (i = 0; i < sortArr.length; i++) {
                key1 = sArr[i]
                val1 = rows[0][key1]
                val2 = rows[0][key1 + "__" + vizOptions.manipulateColumn.replace(".","__")]
                // sortArr[i] = sortArr[i] + (vizOptions.pivotSub ? "'" + rows[0][vizOptions.pivotSub.replace(".","__")]?.substring(2,4) : '');
                key2 =  sortArr[i] + "__" + vizOptions.manipulateColumn.replace(".","__")
                rows[0][sortArr[i]] = val1
                rows[0][sortArr[i] + "__" + vizOptions.manipulateColumn.replace(".","__")] = val2
                for (let j = 0; j < pivotColumns.length; j++) {
                    if (pivotColumns[j].key === key1) {
                        pivotColumns[j].key = sortArr[i]
                        pivotColumns[j].title = sortArr[i]
                        pivotColumns[j].shortTitle = sortArr[i]
                        pivotColumns[j].children[0].dataIndex = key2
                    }
                }
            }
        }

        tableColumns.push(pivotColumns[0])
        for (i = 0; i < sortArr.length; i++) {
            let itm = pivotColumns.find(item => {if (item.key === sortArr[i]) return item})
            // itm = itm.find(item =>{if (item.children.key !== vizOptions.removeMeasure) return item})
            if (vizOptions.excludeMeasures && itm) {
                for (let a = 0; a < itm.children.length; a++) {
                    for (let b = 0; b < vizOptions.excludeMeasures.length; b++) {
                        if(itm.children[a].key === vizOptions.excludeMeasures[b]){
                            itm.children.splice(a,a)
                        }
                    }
                }                    
            }
            // tableColumns.push(itm)
            itm ? tableColumns.push(itm) : 
                tableColumns.push({key : sortArr[i], type: "string", title: sortArr[i], shortTitle: sortArr[i],
                    children: [{dataIndex: sortArr[i], key: sortArr[i], shortTitle: sortArr[i], title: sortArr[i], type: "string"}]}) 
        }
    }
    else
        tableColumns = pivotColumns
    
    

    
    const handleCsvExport = () => {
        if (columns && rows) {
        var data = [];
        rows.forEach(element => {
            let obj = {}
            columns.forEach((e) => {
            if (e.type === 'date' && element[e.accessor] !== null) {
                element[e.accessor] = moment(element[e.accessor]).format(defaultDateFormat);
            }
            obj[e.Header] = element[e.accessor]
            })
            data.push(obj)
        });
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.json_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws, 'test')
        XLSX.writeFile(wb, `${title} ${moment(Date()).format("YYYYMMDDHHmmss")}.csv`)
        }
    }

    let measures = resultSet.loadResponse?.pivotQuery?.measures;
    let selectedColumnKeys = columnsDef?.map(c => c.name) || [];
    let columns = isPivotTable ?
        tableColumns?.filter((col) => col?.children || selectedColumnKeys.includes(col.key))?.map(
            (col) => {
                const colDef = columnsDef?.find(c => c.name === col.key)
                return buildColumns(isPivotTable, defaultDateFormat, measures || [], columnsDef, colDef, col);
            }
        )
        : columnsDef?.map(
            (colDef) => {
                const col = tableColumns?.find(c => colDef.name === c.key);                
                return buildColumns(isPivotTable, defaultDateFormat, measures || [], columnsDef, colDef, col);
            }
        );

    // config?.config for backward compatablility
    const grandTotalType = config?.config?.grandTotalType || config?.grandTotalType;

    if (isPivotTable
        && (grandTotalType === "showGrandTotals" || grandTotalType === "showRowGrandTotals")
        && measures
    ) {
        const grandTotalColumn = buildGrandTotalsColumnsGroup(
            measures,
            pivotConfig,
            columnsDef
        );
        if (grandTotalColumn)
            columns.push(grandTotalColumn);
    }


    // if (vizOptions.sortColumnsUsing && rows.length > 0) {
    //         rearrangeRows(rows)
    //         finalManipulation(rows)
    // }

    const nodata = !rows?.length > 0;
    const columnsMemo = useMemo(() => columns, [pivotTable, resultSet, pivotConfig, vizOptions ,vizOptions.rolling,columns]);
    const rowsMemo = useMemo(() => {
        if (vizOptions.sortColumnsUsing && rows.length > 0) {
            let rowNew = rearrangeRows(rows)
            rowNew = finalManipulation(rowNew,vizOptions,mthArr)
            return rowNew
        }
    }, [pivotTable, resultSet, pivotConfig, vizOptions,rows]);
    return  <DashboardItem nodata={nodata} title={title} subtitle={subtitle} download={download} chartHelpContextKey={chartHelpContextKey} hideToggle={true} isPivotTable={isPivotTable} chartRef={chartRef} onCsvExport={handleCsvExport}>
                <div ref={rref} style={{position: 'relative', height: '100%'}}>
                    <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}>
                        {/* <YADataTable avatar={avatar} ref={tableRef} fluidLayout={fluidLayout} ragCompare={vizOptions.ragCompareColumn} columns={columns} data={rows} ragThresholds={ragThresholds}  totalType={grandTotalType} exportFileName="Analytics" canSearch={true} {...(config || {})} /> */}
                        <YADataTable avatar={avatar} ref={tableRef} fluidLayout={fluidLayout} ragCompare={vizOptions.ragCompareColumn} columns={columnsMemo} data={rowsMemo}  ragThresholds={ragThresholds}  totalType={grandTotalType} exportFileName="Analytics" canSearch={true} {...(config || {})} />
                    </div>
                </div>
            </DashboardItem>
}

export default PivotTableRenderer;