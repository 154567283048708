import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from "react-router-dom";
import { formatAmount, getDrilldownPath } from 'utils';
import colors from "assets/theme/base/cloudForecasts/colors";
import { useResizeDetector } from 'react-resize-detector';
import { useYADialog } from "components/YADialog";
import { convertRStoGraph_CF, removeSessionFilter_CF } from "utils"
import { isTimeDimensionQuery } from 'utils/dashboard';
import { parseAndMergeTableResultset_CF } from 'utils/charts';
import DashboardItem from 'components/DashboardItem_CF';
import { useDashboardContext } from 'components/DashboardContext';
import useFetchRequest from 'hooks/useFetchRequest';

import _ from 'lodash';
const LineChartRenderer_CF = ({ loading, title, subtitle, chartHelpContextKey, resultSet, vizOptions, vizState }) => {

    const [state,] = useDashboardContext();

    const chartRef = createRef();const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const { width, height, ref: rref } = useResizeDetector();

    const { showReport } = useYADialog();
    let navigate = useNavigate()
    let location = useLocation()
    let currentFilters

    if (loading)
        return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

    let parsedResultset = [];
    if (isTimeDimensionQuery(resultSet.loadResponses[0].query)) {
        // parsedResultset = parseTableResultset(resultSet, state, vizOptions)
        parsedResultset = parseAndMergeTableResultset_CF(resultSet, state, vizOptions)
        currentFilters = removeSessionFilter_CF(resultSet.loadResponses[0].query.filters, vizOptions)
    }
    else {
        parsedResultset = resultSet.tablePivot();
        currentFilters = removeSessionFilter_CF(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    }

    useEffect(async () => {
        let measuresCol = vizState["query"]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = vizState['query']?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = 5.8;
        setTableVizOptions(tableVizOptions)
    },[vizOptions, vizState, toggleType === 'table'])

    // currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)
    var graphData = convertRStoGraph_CF(parsedResultset, vizOptions.deviation || vizOptions.rolling ? colors.deviationColors : colors.graphColors, vizOptions.TypeOfLineChart, vizOptions)
    const { response } = vizOptions?.rolling ? useFetchRequest(`/api/forecastInput/forecastRolling/${sessionStorage["yearId"]}?teamName=${sessionStorage["Forecast Team"]}`) : ""
    const categoriesForRollingMonths = response?.map(i => i.name)
    let actualRangeForRollingMonths = response?.map(i => i.actualAmount)
    let forecastRangeForRollingMonths = response?.map(i => i.forecastedAmount)
    for (let i = 0; i < actualRangeForRollingMonths?.length; i++) {
        if (actualRangeForRollingMonths[i] == null) {
            actualRangeForRollingMonths[i] = 0;
        }
    }
    for (let i = 0; i < forecastRangeForRollingMonths?.length; i++) {
        if (forecastRangeForRollingMonths[i] == null) {
            forecastRangeForRollingMonths[i] = 0;
        }
    }
    vizOptions?.rolling && Object.values(graphData.range).length > 0 ? Object.values(graphData.range)[0].data = actualRangeForRollingMonths : ""
    vizOptions?.rolling && Object.values(graphData.range).length > 0 ? Object.values(graphData.range)[1].data = forecastRangeForRollingMonths : ""

    var opts = {
        chart: {
            width: width,
            height: height,
            type: 'spline',
            style: { fontFamily: 'inherit', fontSize: '14px', },
            spacingBottom: 0,
            // spacingTop: 0,
            spacingRight: 0,
            spacingLeft: 0,
        },
        title: { text: '' },
        exporting: { enabled: false },
        lang: { thousandsSep: ',' },
        credits: { enabled: false },
        tooltip: {
            outside: false,
            formatter: function () {
                return vizOptions.deviation ? `<b>${this.point.category}</b><br/>${this.point.series.name}: <b>${Highcharts.numberFormat(this.point.y, 0, ".", ",")}%</b>`
                    : `<b>${this.point.category}</b><br/>${this.point.series.name}: <b>$${Highcharts.numberFormat(this.point.y, 0, ".", ",")}</b>`
            }
        },
        xAxis: {
            categories: vizOptions?.rolling ? categoriesForRollingMonths : Array.from(graphData.categories)
        },
        yAxis: [{
            gridLineWidth: 0 ,
            softMax: 100,
            reversed: false,
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return vizOptions.deviation ? this.value + '%' : formatAmount(Math.abs(this.value)).replace(/ /g, '').replace('.0', '');
                }
            },
        }
        ],
        plotOptions: {
            series: {
                cursor: "pointer",
                lineWidth: 3,
                groupPadding: 0.1,
                // pointPadding: 0,
                borderWidth: 0,
                pointStart: 0,
                marker: {
                    enabled: false
                },
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                point: {
                    events: {
                        click: function (event) {
                            var obj = Object.assign([], [...currentFilters]);
                            if (vizOptions.category)
                                // if (!obj.find((({name}) => name === vizOptions.category))) 
                                if (obj.find((({ name }) => name === vizOptions.category))) {
                                    _.remove(obj, { name: vizOptions.category })
                                    obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                                else {
                                    obj.push({ "name": vizOptions.category, "values": [event.point.category] })
                                }
                            if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                vizOptions.excludeFilters.map((fil) => {
                                    if (obj.find((({ name }) => name === fil)))
                                        _.remove(obj, { name: fil })
                                })
                            }
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj })
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(vizOptions["popupTo"], obj, null);
                        }
                    }
                }
            }
        },
        series: Object.values(graphData.range)

    }

    const nodata = graphData.categories?.size === 0;
    return ( toggleType === 'table' && tableVizOptions ?
    <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet}/> :
        <DashboardItem nodata={nodata} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} noOptions={vizOptions.noOptions} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={resultSet?.tablePivot()}>
            <div ref={rref} style={{ position: 'relative', height: '100%' }}>
                <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 }}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                </div>
            </div>
        </DashboardItem>
    )
}

export default LineChartRenderer_CF;