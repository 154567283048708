import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import DataTableBodyCell from "./DataTableBodyCell";
import DataTableHeadCell from "./DataTableHeadCell";

export const DraggableTableRow = ({ row, setIsDragging }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: row.original.id
  });
  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        background: isDragging ? 'rgba(0, 0, 0, 0.04)' : null,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          cursor: isDragging ? "grabbing" : "grab",
        }
      }}
      {...attributes} {...listeners}
      {...row.getRowProps()}
    >
      {isDragging ? (
        setIsDragging(isDragging),
        <DataTableBodyCell colSpan={row.cells.length}>&nbsp;</DataTableBodyCell>
      ) : (
        row.cells.map((cell) => {
          return (
              cell.column.isHeader ? (
              <DataTableHeadCell
                  key={row.id}
                  disableSorting={true}
                  sorted={false}
                  align={cell.column.align ? cell.column.align : "left"}
                  {...cell.getCellProps()}
              >
                  {cell.render("Cell")}
              </DataTableHeadCell>
              ) : (
              <DataTableBodyCell
                key={row.id}
                align={cell.column.align ? cell.column.align : "left"}
                {...cell.getCellProps()}
              >
                {cell.render("Cell")}
              </DataTableBodyCell>
              )
            );
        })
      )}
    </TableRow>
  );
};
