import MDBox from 'components/MDBox';
import DataTable from 'components/DataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, ListItem, ListItemAvatar, ListItemText, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import MDAvatar from 'components/MDAvatar';
// import RowMenu from 'components/RowMenu';
import MDBadge from "components/MDBadge";
import AlertForm from './components/AlertForm';
import AlertRunHistory from './components/AlertRunHistory';
import { useNotificationSubscription } from 'hooks/useNotificationManager';
import { NotificationType } from 'constants';
import { generateUUID } from 'utils';
import { NotificationStatus } from 'constants';

const auditFieldStyles = ({ typography: { size } }) => ({
    "& .MuiListItemText-primary, & .MuiListItemText-secondary": {
        fontSize: size.xs,
        lineHeight: size.sm
    },
    "& .MuiListItemAvatar-root": {
        minWidth: "inherit",
        mr: .5
    }
})

const buildRows = (data) => {
    const rows = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((r) => {
            let row = {};
            Object.keys(r).forEach((k) => {
                row[k.replace(/\./g, '__')] = r[k];
            });
            rows.push(row);
        });
    }
    return rows;
};

const Alerting = () => {
    const [step, setStep] = useState('LOADING');
    const handleError = useHandleError();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const { showAlert, showPrompt, showSnackbar, showCustomForm, addNotification } = useYADialog();

    useNotificationSubscription(NotificationType.Alert, (notification) => {
        console.log(notification)
        setRefresh(Math.random());
    });

    const handleClose = () => {
        setRefresh(Math.random());
    };

    const handleAddButtonClick = useCallback(
        () => {
            showCustomForm("New Alert", () => <AlertForm onClose={handleClose} />, null, null, null, 'sm');
        },
        []
    );

    const handleEdit = (pkId) => {
        showCustomForm("Edit Alert", () => <AlertForm mode="edit" alertId={pkId} onClose={handleClose} />, null, null, null, 'sm');
    };

    const handleDelete = (pkId) => {
        showPrompt('Delete', 'Are you sure you want to delete?', async () => {
            const [err, data] = await fetchRequest.delete(`/api/alert/${pkId}`);
            if (err) {
                showAlert('Delete', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    showSnackbar('Alert deleted successfully', 'success');
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const handleRun = (pkId) => {
        const requestId = generateUUID();

        showPrompt('Run', 'Are you sure you want to run the alert?', async () => {
            const [err, data] = await fetchRequest.post(`/api/alert/${pkId}/run`, { requestId });
            if (err) {
                showAlert('Run', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    const notification = ({ requestId, type: NotificationType.Alert, message: "Running Alert", status: NotificationStatus.Processing, persist: true, details: { alertId: pkId } });
                    addNotification(notification);
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Run', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const handleViewRunHistory = (pkId) => {
        showCustomForm("Alert Run History", () => <AlertRunHistory alertId={pkId} />);
    };

    const handleActivate = (pkId) => {
        showPrompt('Activate', 'Are you sure you want to activate the alert?', async () => {
            const [err, data] = await fetchRequest.post(`/api/alert/${pkId}/activate`);
            if (err) {
                showAlert('Activate', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    showSnackbar('Alert activated successfully', 'success');
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Activate', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const handleDeactivate = (pkId) => {
        showPrompt('Deactivate', 'Are you sure you want to deactivate the alert?', async () => {
            const [err, data] = await fetchRequest.post(`/api/alert/${pkId}/deactivate`);
            if (err) {
                showAlert('Deactivate', err?.data?.message || 'Something went wrong. Contact your administrator.');
            }
            else
                if (data && data.result === true) {
                    showSnackbar('Alert deactivated successfully', 'success');
                    handleClose();
                }
                else if (data && data.result === false) {
                    showAlert('Deactivate', data.message || 'Something went wrong. Contact your administrator.');
                }
        });
    };

    const columns = useMemo(() => ([
        {
            Header: "Actions", disableSorting: true, accessor: "id", width: 140, Cell: ({ cell: { row: { original } } }) => {
                // let options = [];
                // if (original?.active)
                //     options.push(...[
                //         {
                //             label: "Deactivate", onClick: () => {
                //                 handleDeactivate(original?.id)
                //             }
                //         }
                //     ]);
                // else {
                //     options.push(...[
                //         {
                //             label: "Edit", onClick: () => {
                //                 handleEdit(original?.id)
                //             }
                //         },
                //         {
                //             label: "Activate", onClick: () => {
                //                 handleActivate(original?.id)
                //             }
                //         }
                //     ]);
                // }
                // options.push(...[
                //     {
                //         label: "Run", onClick: () => {
                //             handleRun(original?.id)
                //         }
                //     },
                //     {
                //         label: "View run history", onClick: () => {
                //             handleViewRunHistory(original?.id)
                //         }
                //     },
                //     {
                //         label: "Delete", onClick: () => {
                //             handleDelete(original?.id)
                //         }
                //     }
                // ]);

                return (
                    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
                        {original?.active ? 
                            <>
                                <IconButton sx={{ padding: 0, paddingRight: 1 }}>
                                    <Icon fontSize='small' color="disabled">edit_off</Icon>
                                </IconButton>
                                <Tooltip title="Deactivate" placement="top">
                                    <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleDeactivate(original?.id)}>
                                        <Icon fontSize='small'>toggle_off</Icon>
                                    </IconButton>
                                </Tooltip>
                            </>
                            :
                            <>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleEdit(original?.id)}>
                                        <Icon fontSize='small'>edit</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Activate" placement="top">
                                    <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleActivate(original?.id)}>
                                        <Icon fontSize='small'>toggle_on</Icon>
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        <Tooltip title="Run" placement="top">
                            <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleRun(original?.id)}>
                                <Icon fontSize='small'>play_arrow</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Run History" placement="top">
                            <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleViewRunHistory(original?.id)}>
                                <Icon fontSize='small'>history</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                            <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => handleDelete(original?.id)}>
                                <Icon fontSize='small' color='error'>delete</Icon>
                            </IconButton>
                        </Tooltip>
                        
                    </MDBox>
                );
            }
        },
        {
            Header: "Name", accessor: "name", width: 300, Cell: ({ cell: { value, row: { original } } }) => {
                return <Link to={`/alerting/${original["id"]}/alert-details`}><MDTypography
                    display="flex" alignItems="center" variant="caption" color="info" fontWeight="medium"
                    sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                >{value}</MDTypography>
                    <MDTypography mt={.25} display="flex" alignItems="center" variant="caption" whiteSpace="pre-wrap">{original?.desc}</MDTypography>
                </Link>
            }
        },
        {
            Header: "Metric Name", accessor: "metric__name", width: 200, Cell: ({ cell: { value } }) => {
                return <MDTypography display="flex" alignItems="center" variant="caption" whiteSpace="pre-wrap">{value}</MDTypography>
            }
        },
        {
            Header: "Type", accessor: "type", width: 100, Cell: ({ cell: { value } }) => {
                return <MDTypography display="flex" alignItems="center" variant="caption">{value}</MDTypography>
            }
        },
        {
            Header: "Last Run", accessor: "lastRunAt", width: 100, Cell: ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">{value ? moment(value).fromNow() : ""}</MDTypography>
            }
        },
        {
            Header: "Last Run Status", accessor: "lastRunStatus", Cell: ({ cell: { value } }) => {
                if ((value || "") === "")
                    return <MDTypography variant="caption"></MDTypography>
                return <MDBadge container badgeContent={value} color={value.toLowerCase()} variant="gradient" size="xs" />
            }
        },
        {
            Header: "Active", accessor: "active", width: 80, Cell: ({ cell: { value } }) => {
                return <MDTypography variant="caption" color="dark">{value === true ? "Yes" : "No"}</MDTypography>
            }
        },
        {
            Header: "Created", accessor: "createdAt", Cell: ({ cell: { row: { original } } }) => {
                return <ListItem component="div" sx={theme => auditFieldStyles(theme)}>
                    <ListItemAvatar>
                        <MDAvatar name={original["createdByUser__name"]} size="xs" sx={{ mr: .75 }} />
                    </ListItemAvatar>
                    <ListItemText primary={original["createdByUser__name"]} secondary={original["createdAt"] ? moment(original["createdAt"]).format("MMM DD YYYY") : ""} />
                </ListItem>
            }
        },
    ]), []);

    const handleExport = async () => {
        await fetchRequest.download(`/api/alert/export`, { responseType: "blob" });
    };

    const handleUploadDialogClose = (uploadSuccess) => {
        if (uploadSuccess) {
          setRefresh(Math.random());
        }
      };

    const handleImportButtonClick = useCallback(
        () => {
            showCustomForm("Import Alerts", () => <AlertForm mode="import" onClose={handleUploadDialogClose} />, null, null, null, 'sm');
        },
        []
    );

    const getMenuActions = () => {
        let actions = [];
        (step !== 'EMPTY' && actions.push({ label: "Export", onClick: handleExport}))
        return actions;
    }

    useEffect(() => {
        async function getList() {
            setLoading(true);
            var [err, data] = await fetchRequest.get(`/api/alert/list`);
            if (err) {
                handleError(err);
            } else {
                if (data && Array.isArray(data) && data.length > 0) {
                    setRows(buildRows(data));
                    setStep('LOADED');
                } else {
                    setRows([]);
                    setStep('EMPTY');
                }
            }
            setLoading(false);
        }
        getList();
    }, [refresh]);

    const renderPrimaryActions = () =>
    ( step !== 'EMPTY' && step === 'LOADED' && <MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row">
    <MDButton
      variant="outlined"
      color="info"
      startIcon={<Icon>add</Icon>}
      onClick={handleAddButtonClick}
      sx={{ mr: 1 }}
    >
      Add New
    </MDButton>
    <MDButton data-testid = {"upload"} variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={()=> {handleImportButtonClick()}}>
        Import
    </MDButton>
    </MDBox>)

    const renderAddButton =
    (<MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row">
        <MDButton
            variant="outlined"
            color="info"
            startIcon={<Icon>add</Icon>}
            onClick={handleAddButtonClick}
            sx={{ mr: 1 }}
        >
            Add New
        </MDButton>
        <MDButton data-testid = {"upload"} variant="gradient" color="info" startIcon={<Icon>cloud_upload</Icon>} onClick={()=> {handleImportButtonClick()}}>
            Import
        </MDButton>
        </MDBox>)

    if (step === 'LOADING') {
        return <YASkeleton variant="dashboard-loading" />;
    }

    return (
        <>
            <PageHeader
                title={"Alerting"}
                subtitle={"Manage alerts"}
                // primaryActionComponent={renderPrimaryActions}
            />
            <MDBox p={3} pt={1}>
                {step === 'EMPTY' && (
                    <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="calc(100vh - 300px)"
                    >
                        <EmptyState
                            size="large"
                            image={new_item_img}
                            title={`No Alerts Yet`}
                            description={`Click on the '+ add new' button to add a new alert.`}
                            actions={renderAddButton}
                        />
                    </MDBox>
                )}
                {step === 'LOADED' &&
                    <>
                        <Card sx={{ height: '100%' }} px={0}>
                            <DataTable
                                table={{ columns, rows }}
                                showTotalEntries={true}
                                isSorted={true}
                                noEndBorder
                                newStyle1={true}
                                entriesPerPage={true}
                                canSearch={true}
                                // canFilter={true}
                                primaryRender={renderPrimaryActions()}
                                loading={loading}
                                menuActionRender={getMenuActions()}
                            />
                        </Card>
                    </>
                }

            </MDBox>
        </>
    );
};

export default AnimatedRoute(Alerting);
