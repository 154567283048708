import MDBox from 'components/MDBox';
import _ from 'lodash';
import DataTable from 'components/DataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, Menu, MenuItem, Tooltip, Autocomplete, IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import numeral from 'numeral';
import { useAppController } from 'context';
import { useImmer } from 'use-immer';
import MDInput from 'components/MDInput';
import FilterChip from 'components/FilterChip';
import * as XLSX from 'xlsx';
import TeamRuleForm from './components/TeamRuleForm';
import CopyFilesDialog from 'pages/Dataflow/components/CopyFilesDialog';// import{backgroundProcessCheck} from '../../../../utils'
import { getPublishedByMonth } from 'utils';
import useFetchRequest from "hooks/useFetchRequest";

const FilterDropdown = (props) => {
  const { formId, filter, onFilterChange } = props;
  const { name, displayName, dataSource, values } = filter;
  const [options, setOptions] = useState(dataSource?.type === 'static' ? dataSource.data : []);
  const [loading, setLoading] = useState(dataSource?.type !== 'static');

  useEffect(() => {
    async function getOptions() {
      setLoading(true);
      const [error, data] = await fetchRequest.get(`/api/assetRules/${formId}/${name}`);
      if (error)
        console.error(error)
      setOptions(data || []);
      setLoading(false);
    }
    if (dataSource?.type !== 'static') getOptions();
  }, [name]);

  const handleOnFilterChange = (selectedFilter) => {
    onFilterChange({
      name: selectedFilter.name,
      type: selectedFilter.type,
      operator: selectedFilter.operator,
      values: selectedFilter.values.map(v => options.find(o => o.label === v)?.value)
    });
  }

  const sOptions = useMemo(() => options?.map(o => o.label), [options]);
  const filterValue = { name, operator: "eq", values: values?.map(v => options?.find(o => o.value === v)?.label) };
  return (
    <FilterChip loading={loading} dismissible={false} openOnMount={false} key={name} dataType={"select"} name={name} field={displayName} filterValue={filterValue} options={sOptions} onFilterSave={handleOnFilterChange} />
  );
};
let linkFieldAccessor
let linkFieldColumns = []

// const GetRulePopup = (props) => {
//   const { condition } = props
//   return (
//     <MDBox  pb={3}
//     minWidth="800px"
//     width="100%"
//     >
//     {
//       <DataTable
//           variant="tile"
//           table={{ columns: toAssetColumn, rows: condition.toAssets }}
//           // containerMaxHeight={424}
//           showTotalEntries={false}
//           isSorted={false}
//           noEndBorder
//           entriesPerPage={false}
//           canSearch={false}
//       />
//     }
//   </MDBox>
//   )
// }

const buildColumns = (masterDef, defaultDateFormat, published) => {
  linkFieldAccessor = []
  const columns = [];
  if (!masterDef.readonly && !published)
  columns.push({
    Header: 'Actions',
    accessor: 'actions',
    align: 'left',
    disableSorting: true,
    disableFilters: true,
  });
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    masterDef.fields?.filter(f => !f.hidden)?.forEach((f) => {
      let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
      let accessor = f.schemaName;
      if (f.type === 'dropdown' && !f.dataSource.consumptionAsset) {
        if (f.dataSource.type === "static") {
          accessor = `${f.schemaName}__displayName`
        } else {
          accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
        }
      }
      if (f.type === "rule") {
        accessor = "ruleStr"
      }
      if (f.type === "toAssetrule") {
        accessor = "toAssetRuleStr"
      }
      col['Header'] = f.displayName;
      col['accessor'] = accessor;
      col['Cell'] = ({ cell: { value } }) => {
        if (f.type === "currency")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
        else if (f.type === "datepicker")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
        else if (f.type === 'rule')
          return (value || "") !== "" ? <Tooltip placement="top" title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null
        // else if (f.type === 'toAssetrule')
        //   return (value || "") !== "" ? <Tooltip placement="top" sx={{":hover": {cursor: "pointer"}}} onClick={() => handlePopup(row.original.toAssetRule)} title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null
        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{(f.name == 'fromAsset' || f.name === 'toAsset') ? _.capitalize(value) : value}</MDTypography>
      };
      col['dataType'] = f.filterType || f.type
      col['disableFilters'] = f.disableFilters || false
      f.dataSource && f.dataSource.linkField != undefined ? linkFieldColumns.push(col) : columns.push(col);
      if (f.dataSource) {
        let columcreat = (f) => {
          let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
          let accessor = f.schemaName;
          if (f.type === 'dropdown') {
            accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
          }
          col['Header'] = f.displayName;
          col['accessor'] = accessor;
          col['Cell'] = ({ cell: { value } }) => {
            if (f.type === "currency")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
            else if (f.type === "datepicker")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
            return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value}</MDTypography>
          };
          col['dataType'] = f.filterType || f.type
          col['disableFilters'] = f.disableFilters || false
          linkFieldColumns.push(col);
          linkFieldAccessor.push(accessor)
          if (f.dataSource) {
            linkFieldCheck(f)
          }
        }
        let linkFieldCheck = (f) => {
          if (f.dataSource.linkField) {
            columcreat(f.dataSource.linkField)
          }
        }
        f.dataSource.linkField ? linkFieldAccessor.push(accessor) : linkFieldAccessor
        linkFieldCheck(f)
        if (linkFieldColumns.length > 0) {
          linkFieldColumns = linkFieldColumns.reverse()
          linkFieldColumns.forEach(item => { columns.push(item) })
          linkFieldColumns = []
        }
      }
    });
  }
  return columns;
};

const buildRows = (pkColumn, data, onEdit, onDelete, isDeleting) => {
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r) => {
      let row = {};
      Object.keys(r).forEach((k) => {
        row[k.replace(/\./g, '__')] = r[k];
      });
      if (linkFieldAccessor.length > 0) {
        let nameSet = row[linkFieldAccessor[0]].split(" | ").reverse()
        for (let i = 0; i < linkFieldAccessor.length; i++) {
          row[linkFieldAccessor[i]] = nameSet[i]
        }
      }

      row['actions'] =
        r?.taxonomy === true ? (
          <span></span>
        ) : (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
            <Tooltip title="Edit" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => isDeleting ? "" : onEdit(r[pkColumn])}>
                <Icon disabled={isDeleting} fontSize="small">edit</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => isDeleting ? "" : onDelete(r[pkColumn])}>
                <Icon disabled={isDeleting} fontSize="small" color="error"> delete </Icon>
              </IconButton>
            </Tooltip>
            {/* <MDTypography
              display="flex"
              alignItems="center"
              component="a"
              href="#"
              onClick={() => isDeleting ? "" : onEdit(r[pkColumn])}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              <Icon disabled={isDeleting} fontSize="small">edit</Icon>&nbsp;Edit
            </MDTypography>
            <MDTypography
              display="flex"
              alignItems="center"
              ml={3}
              component="a"
              href="#"
              onClick={() => isDeleting ? "" : onDelete(r[pkColumn])}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              <Icon disabled={isDeleting} fontSize="small" color="error"> delete </Icon>
              &nbsp;Delete
            </MDTypography> */}
          </MDBox>
        );
      rows.push(row);
    });
  }
  return rows;
};

const filtersInitiaized = (filters) => {
  let initiaized = false;
  filters?.forEach(f => {
    if (f.values && Array.isArray(f.values) && f.values?.length > 0) {
      initiaized = true;
      return;
    }
  });
  return initiaized;
}

// const buildPopupColumns = (masterName) => {
//   let columns = [
//     { Header: masterName, accessor: "masterRecordId", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="medium" color="dark">{value}</MDTypography> } },
//     { Header: "Message", accessor: "message", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="small" color={value.includes("success") ? "success" : "error"}>{value}</MDTypography> } }
//   ]
//   return columns;
// }

// const buildPopupRows = (masterName, data) => {
//   let rows = data.map(item => {
//     return {
//       "masterRecordId": item[masterName],
//       "message": item["message"],
//     }
//   })
//   return rows
// }

const chipStyles = ({ palette: { white } }) => ({
  cursor: 'pointer',
  border: 'none',
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'row',
  minWidth: 0,
  pb: 1,
  pl: 0.5,
  margin: '.75 0',
  alignItems: 'center',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  fontSize: '15px',
  backgroundColor: white,
  height: 32,
  position: 'relative',
  "& .MuiOutlinedInput-notchedOutline": { border: 'none' },


  "& .MuiTypography-root, & .MuiIcon-root": { color: '#435cc8!important' },
})

const TeamMapping = (props) => {
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const { setYearFilter, setYearFilterName, yearFilter, years, months, monthFilter, setMonthFilter, yearFilterName, monthFilterName, setMonthFilterName, published, setPublished, setYears, setMonths } = props
  const [masterDef, setMasterDef] = useImmer(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  // const [popupRows, setpopupRows] = useState([])
  // const [popupColumns, setpopupColums] = useState([])
  // const [popup, setpopup] = useState(false)
  const [openMenu, setOpenMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false)
  const [list, setList] = useState([])
  const [copyRuleDialog, setCopyRuleDialog] = useState(false);
  const { response: levelsRes, error: levelsErr, loading: levelsLoading } = useFetchRequest(`/api/dataflow/categories`);

  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
  let filters = []
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: filters });

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }

  useEffect(() => {
    if (!levelsLoading) {
      if (levelsErr !== null) {
        handleError(levelsErr);
      }
      else if (levelsRes !== null) {
        let currentYearIndex = levelsRes.years?.length - 1;
        const currentFinancialYear = levelsRes.currentFinancialYear;
        if (currentFinancialYear) {
          const index = levelsRes.years?.map(y => y.name).indexOf(currentFinancialYear.value);
          if (index > -1)
            currentYearIndex = index;
        }
        setYears(levelsRes.years)
        setMonths([...levelsRes.months])
        setYearFilter(yearFilter ? yearFilter : levelsRes.years[currentYearIndex]?.id)
        setYearFilterName(yearFilterName ? yearFilterName : levelsRes.years[currentYearIndex]?.name);
        setMonthFilter(monthFilter ? monthFilter : [...levelsRes.months].find((o) => o.srl === 1).id)
        setMonthFilterName((monthFilterName ? monthFilterName : [...levelsRes.months].find((o) => o.srl === 1).name))
      }
    }
  }, [levelsLoading, levelsRes])

  useEffect(async () => {
    if (yearFilter && monthFilter) {
      const response = await getPublishedByMonth(yearFilter, monthFilter)
      if (response) {
        setPublished(true)
      } else {
        setPublished(false)
      }
    }
  }, [yearFilter, monthFilter])

  // const handlePopup = () => {
  //   setpopup(false)
  //   setRefresh(Math.random());
  // }
  const handleClose = () => {
    setRefresh(Math.random());
  };

  const handleEdit = (pkId) => {
    showCustomForm(`Edit Team Rule`, () => <TeamRuleForm onClose={handleClose} mode="edit" pkId={pkId} yearNameId={yearFilter} monthNameId={monthFilter} />, handleClose, "edit", pkId, "md");
  };


  const deleteMaster = async (pkId) => {
    setIsDeleting(true)
    const [err, data] = await fetchRequest.delete(`/api/teamRules/${pkId}`);
    if (err) {
      if (err.data?.message) {
        handleClose();
        showSnackbar(err.data?.message, "error")
      } else {
        handleClose();
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data deleted successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
      }
    setIsDeleting(false)
  };

  const handleDeleteSuccess = (pkId) => {
    deleteMaster(pkId);
  };

  const handleDelete = (pkId) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
  };

  const deleteMasterMultipleRecords = async (selectedRows) => {
    setIsDeleting(true)
    const [err, data] = await fetchRequest.post(`/api/teamRules/deleteMultiple`, selectedRows);

    if (err) {
      if (err.data?.message) {
        handleClose();
        setRefresh(Math.random());
        showSnackbar(err.data?.message, "error")
      } else {
        handleClose();
        setRefresh(Math.random());
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    }
    else if (data && data.result === true) {
      showSnackbar('Data deleted successfully', 'success');
      handleClose()
      // setRefresh(Math.random());

    }
    setIsDeleting(false)
  }
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
    // showCustomDrawer('', () => <UserInfoDrawer />);
  };

  const handleDeleteMultiple = (selectedRows) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => deleteMasterMultipleRecords(JSON.stringify(selectedRows)));
  };

  const getAppliedFilters = () => {
    if (!masterDef.filters || masterDef.filters.length === 0)
      return null;

    return {
      "filters": JSON.stringify(
        masterDef.filters?.map(f => ({
          name: f.name,
          operator: f.operator,
          value: f.values
        })) || []
      )
    };
  };

  // const handlePopup = (condition) => {
  //   const parsedCondition = parseJsonString(condition)
  //   showCustomForm(
  //     "To Assets",
  //     () => <GetRulePopup condition={parsedCondition} />,
  //     null,
  //     null,
  //     null,
  //     '1080px'
  // );
  // }

  useEffect(() => {
    async function getMasterDef() {
      let [err, data] = await fetchRequest.get(`/api/teamRules/teamRuleDef`);
      if (err) {
        handleError(err);
      } else {
        data.yearNameId = yearFilter;
        data.monthNameId = monthFilter;
        setMasterDef(data);
        // if (data.filters && filtersInitiaized(data.filters))
        //   setFilters(data.filters)
        setColumns(buildColumns(data, defaultDateFormat, published));
      }
    }
    getMasterDef();
  }, [published]);

  useEffect(() => {
    async function getList() {
      setLoading(true);
      const appliedFilters = getAppliedFilters();
      if (yearFilter && monthFilter) {
        let [err, data] = await fetchRequest.post(`/api/teamRules/list/${yearFilter}/${monthFilter}`, appliedFilters);
        // let [err, data] = await fetchRequest.post(`/api/teamRules/list/${yearFilter}/${monthFilter}`) || []
        // let err = false;
        // let data = []
        if (err) {
          handleError(err);
        } else {
          if (data && Array.isArray(data) && data.length > 0) {
            setList(data)
            setRows(buildRows(masterDef.pkColumn || 'id', data, handleEdit, handleDelete, isDeleting));
            setStep('LOADED');
          } else {
            setRows([]);
            setStep('EMPTY');
          }
        }
        setLoading(false);
      }
    }
    if (masterDef) {
      getList();
    }
  }, [masterDef, yearFilter, monthFilter, refresh, isDeleting, published]);

  if (step === 'LOADING') {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const { displayName, singularDisplayName, desc, message, canFilter } = masterDef;

  const handleAddButtonClick = () => {
    // showForm(`New ${singularDisplayName || displayName}`, masterDef, handleClose);
    showCustomForm(`New ${singularDisplayName || displayName}`, () => <TeamRuleForm onClose={handleClose} yearNameId={yearFilter} monthNameId={monthFilter} />, handleClose, null, null, 'md');
    handleCloseMenuItem();
  };

  //   const handleUploadButtonClick = async () => {
  //     let bgData = await backgroundProcessCheck();
  //     if(bgData.length>0)
  //     {
  //       showAlert(bgData[0],bgData[1],bgData[2],bgData[3]);
  //     }else
  //     {
  //       showUploadDialog("Data Upload for " + uploadName, { uploadType: uploadType }, handleUploadDialogClose);
  //       handleCloseMenuItem();
  //     }
  //   }

  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  //   const handleUploadDialogClose = (uploadSuccess) => {
  //     if (uploadSuccess) {
  //       setRefresh(Math.random());
  //     }
  //   };

  const handleRulesCopy = () => {
    handleCloseMenuItem()
    setCopyRuleDialog(true);
  }

  const onClose = () => {
    setCopyRuleDialog(false);
    setRefresh(Math.random());
  }

  const handleOnFilterChange = (selectedFilter) => {
    setMasterDef((draft) => {
      let filter = draft.filters?.find(f => f.name === selectedFilter.name);
      filter.operator = selectedFilter.operator;
      filter.values = selectedFilter.values;
    });
  };
  const handleDownload = async () => {
    let newData = list.map(item => {
      return {
        "From Source": item["fromSource__displayName"],
        "Rule": item["rule"],
        "Team": item['cloudTeam.name'],
        "Sequence": item["sequence"]
      }
    })
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(newData)
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `TeamRules_${yearFilterName} - ${monthFilterName}.xlsx`)
    await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded team rules from ${yearFilterName} - ${monthFilterName}}` })
    handleCloseMenuItem();
  };

  const renderPrimaryActions = () => {
    return (
      <>
        <MDBox pt={0} mt={0} display="flex" flexDirection="row">
          <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            {<>
              <MenuItem key={'download'} onClick={handleDownload}>{"Download"}</MenuItem>
              <MenuItem disabled={published ? true : false} key={'copyrule'} onClick={handleRulesCopy}>{"Copy Rule"}</MenuItem>
            </>
            }
          </Menu>
          {!published && <MDButton data-testid={"addnew"} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
            Add New
          </MDButton>}
          &nbsp;
          <MDBox mt={0} mr={1} pt={0}>
            <MDButton
              // size="medium"
              disableRipple
              color="dark"
              variant="text"
              onClick={handleOpenMenu}
              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
              iconOnly
            >
              <Icon px={0} py={0}>more_horiz</Icon>
            </MDButton>
          </MDBox>

        </MDBox>

      </>
    );
  };
  const renderPrimaryActions1 = () => {
    return (
      <>
        {!published &&
          <MDBox flexDirection={"row"}>
            <MDButton
              data-testid={"addnew"}
              variant="outlined"
              color="info"
              sx={{ mr: 4 }}
              startIcon={<Icon>add</Icon>}
              onClick={handleAddButtonClick}
            >
              Add New
            </MDButton>
            <MDButton
              data-testid={"addnew"}
              variant="gradient"
              color="info"
              onClick={handleRulesCopy}
            >
              Copy Rules
            </MDButton>
          </MDBox>}
      </>
    );
  };

  const filterRenderer = () => {
    return (
      <MDBox pb={1}>
        <MDBox display="flex" flex={1} justifyContent="flex-end" alignItems="center" flexWrap="wrap">
          <Icon fontSize="small">calendar_today_two_tone</Icon>
          <Autocomplete
            disableClearable={true}
            value={yearFilter}
            options={years}
            onChange={(event, newValue) => {
              setYearFilter(newValue.id)
              setYearFilterName(newValue.name)
            }}
            color="text"
            fontWeight="medium"
            sx={{
              // ml: 0.5,
              // border:  '1px solid #ffffff',
              "& .MuiOutlinedInput-root": {
                height: 40,
                minWidth: 135,
                // boxShadow: "0 8px 16px #1a488e1f"

              },
              "& .MuiAutocomplete-endAdornment": {
                top: '12px'
              },
              "& .MuiOutlinedInput-input": {
                fontSize: 14,
                fontWeight: 600
              },
              "& .MuiOutlinedInput-input, .MuiAutocomplete-input": {
                // padding: .5, 
              }
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value
            }}
            getOptionLabel={option => {
              if (typeof option === "number")
                return years.find(op => op.id === option)?.name;
              return option.name
            }}
            renderInput={(params) => <MDInput sx={(theme) => chipStyles(theme)} label="" {...params} />}
          />
          <Icon fontSize="medium" color="dark">calendar_month</Icon>
          <Autocomplete
            disableClearable={true}
            value={monthFilter || 0}
            options={months}
            onChange={(event, newValue) => {
              setMonthFilter(newValue.id)
              setMonthFilterName(newValue.name)
            }}
            color="text"
            fontWeight="medium"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 40,
                minWidth: 100,
                maxHeight: 140
              },
              "& .MuiAutocomplete-endAdornment": {
                top: '12px'
              },
              "& .MuiOutlinedInput-input": {
                fontSize: 14,
                fontWeight: 600
              },
              "& .MuiOutlinedInput-input, .MuiAutocomplete-input": {
              }
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value
            }}
            getOptionLabel={option => {
              if (typeof option === "number")
                return months.find(op => op.id === option)?.name;
              return option.name
            }}
            renderInput={(params) => <MDInput data-testid={'MonthFilter'.toLowerCase().replaceAll(' ', '')} sx={(theme) => chipStyles(theme)} label="" {...params} />}
          />
        </MDBox>
      </MDBox>
    )
  }

  //   const renderAddButton = () =>
  //      (
  //       <MDButton
  //         data-testid = {"Upload".toLowerCase().replaceAll(' ', '')}
  //         variant="gradient"
  //         color="info"
  //         startIcon={<Icon>cloud_upload</Icon>}
  //         onClick={handleUploadButtonClick}>
  //         Upload
  //       </MDButton>
  //       // <MDButton
  //       //   variant="outlined"
  //       //   color="info"
  //       //   startIcon={<Icon>add</Icon>}
  //       //   onClick={handleAddButtonClick}
  //       // >
  //       //   Add New
  //       // </MDButton>
  //     );

  const renderFilters = () => {
    return (
      <>
        {masterDef.filters?.map((f) => (
          <FilterDropdown key={f.name} filter={f} onFilterChange={handleOnFilterChange} />
        ))}
      </>
    )
  }

  const defaultFilteresInitiaized = filtersInitiaized(masterDef?.filters);

  return (
    <>
      <PageHeader
        title={displayName}
        subtitle={desc}
        message={message}
        hideBreadcrumbs={true}
        primaryActionComponent={filterRenderer}
        anchor={displayName}
        settingsHeaderFormat={true}
      />
      {/* <MDBox display="flex" sx={{marginBottom: "10px"}}>
        <MDButton data-testid = {uploadName?.toLowerCase().replaceAll(' ', '')} sx={(theme) => tabStyles(theme, { selected: typeFilter === uploadName })} onClick={() => setTypeFilter(uploadName)}>{uploadName.toUpperCase()}</MDButton>
      </MDBox> */}

      {<MDBox p={3} pt={1}>
        {step === 'EMPTY' && (masterDef.filters?.length === 0 || !defaultFilteresInitiaized) && (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 300px)"
          >
            <EmptyState
              size="large"
              image={new_item_img}
              title={`No ${displayName} Yet`}
              description={
                !masterDef.readonly
                  ? `Click on the '+ Add New' button to add a new ${(
                    singularDisplayName || displayName
                  ).toLowerCase()}.`
                  : undefined
              }
              actions={renderPrimaryActions1}
            />
          </MDBox>
        )}
        {copyRuleDialog &&
          <CopyFilesDialog
            categoryFilter={"teamRules"}
            typeFilter={"cloudTeamRules"}
            variant={"copyTeamRules"}
            yearFilter={yearFilter}
            yearFilterName={yearFilterName}
            monthFilter={monthFilter}
            monthFilterName={monthFilterName}
            onClose={onClose}
          />
        }
        {(step === 'LOADED' || (step === 'EMPTY' && masterDef.filters?.length > 0) && defaultFilteresInitiaized) &&
          <>
            <Card sx={{ height: '100%' }} px={0}>
              <DataTable
                containerMaxHeight={500}
                table={{ columns, rows }}
                showTotalEntries={true}
                isSorted={true}
                newStyle1={true}
                isSelectable={published ? false : true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                primaryRender={renderPrimaryActions()}
                filtersComponent={renderFilters()}
                canFilter={canFilter}
                loading={loading}
                deleteMultiple={masterDef.readonly || masterDef.taxonomy ? false : true}
                onDeleteMultiple={handleDeleteMultiple}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
              />
            </Card>
          </>
        }

      </MDBox>}
    </>
  );
};

export default AnimatedRoute(TeamMapping);
