import ChartRenderer from "components/ChartRenderer";
import { useParams, useLocation } from "react-router-dom";
import { setFilter2Query } from 'utils/dashboard';
import { Alert } from "@mui/material";
// import EmptyState from "components/EmptyState";
// import new_item_img from "assets/svg/add_new.svg";
// import { getFiscalMonthsArray } from "utils/budget";
// import useFetchRequest from "hooks/useFetchRequest"
import { useEffect, useState } from "react";
import fetchRequest from "utils/fetchRequest";
import FilterContainer from "components/FilterContainerCF";
import { useImmer } from "use-immer";
import PageHeader from "components/PageHeaderCF";
import AnimatedRoute from "components/AnimatedRoute";
import MDBox from "components/MDBox";
import YASkeleton from "components/YASkeleton";
import useHandleError from "hooks/useHandleError";
import colors from "assets/theme/base/colors";
import { ResponsiveDashboardContainer1 } from "components/ResponsiveDashboardContainer";
import { DashboardContextProvider, initDashboardContext, setDashboardContextFilters, useDashboardContext } from "components/DashboardContext";
import { current } from "immer";
import { applyDefaultFilters, setDrilldownFilters, setSelectedFilter, deleteSelectedFilter } from "utils/dashboard";
import { useAppController } from "context"

const ForecastPage = () => {
  const handleError = useHandleError();
  let { dashboardId } = useParams();
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(false);
  const [dashboardDef, setDashboardDef] = useImmer(null);
  const [filtersCleared, setFiltersCleared] = useImmer(false)
  const [, dispatch] = useDashboardContext();
  const [currentYear, setCurrentYear] = useState([''])
  const [previousYear, setPreviousYear] = useState([''])
  const [firstMonth, setFirstMonth] = useState([''])
  const [currentMonth, setCurrentMonth] = useState([''])
  const [previousMonth, setPreviousMonth] = useState([''])
  const [,setTeamIdForMember] = useState(null);
  const [teamForMember, setTeamsforMember] = useState([]);
  const [,setTeamIdForOwner] = useState(null);
  const [filterChange,setFilterChange] = useImmer('');
  const [teamName,setTeamName] = useState(sessionStorage['Forecast Team']);
  const [controller,] = useAppController();
  let location = useLocation()
  const { userInfo } = controller
  const [currentYearAndMonthWithSpend, setYearAndMonthWithSpend] = useState(null)
  const [currentYearAndMonthWithForecast, setYearAndMonthWithForecast] = useState(null)

  const cloudEngineer = userInfo?.role.includes("cloudEngineer") && !userInfo?.role.includes('admin') && !userInfo?.role.includes('cloudCostOwner') ? true : false
  const cloudCostOwnerOrAdmin = userInfo?.role.includes("cloudCostOwner") || userInfo?.role.includes('admin') ? true : false

  // dashboardId = userInfo?.role === 'admin' && !dashboardId ? 'overview-admin' : userInfo?.role === 'member' && !dashboardId ? 'overview-teams' : userInfo?.role === 'owner' && !dashboardId ? 'overview-admin' : dashboardId
  
  // const { response: data1, error: err1, loading: load } = filterChange && (filterChange.name === 'Year' || filterChange.name === 'Forecast_Month') ? useFetchRequest(`/api/home/currentforecastdetails?filter=${JSON.stringify(filterChange)}`) : useFetchRequest(`/api/home/currentforecastdetails`);

  sessionStorage.setItem("Year", ![null, undefined, 'null', 'undefined', ''].includes(sessionStorage['Year']) ? sessionStorage['Year'] : currentYear && !filterChange ? 
  currentYear : filterChange.name === 'Year' ? 
  filterChange.values : sessionStorage['Year']);
  sessionStorage.setItem("Forecast_Month", ![null, undefined, 'null', 'undefined', ''].includes(sessionStorage['Forecast_Month']) ? sessionStorage['Forecast_Month'] : currentYearAndMonthWithSpend?.["monthName"] && !filterChange ? 
  currentYearAndMonthWithSpend?.["monthName"] : currentYearAndMonthWithForecast?.["monthName"] && !filterChange ? currentYearAndMonthWithForecast?.["monthName"] : filterChange.name === 'Forecast_Month' ? 
  filterChange.values : ![null, undefined, 'null', 'undefined', ''].includes(sessionStorage['Forecast_Month']) ? sessionStorage['Forecast_Month'] : '');
  
  useEffect(() => {
    async function getDashboardDef() {
      const [err2, res] = await fetchRequest.get(`/api/forecastInput/categories?memberId=${userInfo?.sub}`)
      const [err1, data1] = filterChange && (filterChange.name === 'Year' || filterChange.name === 'Forecast_Month') ? await fetchRequest.get(`/api/forecastInput/currentforecastdetails?filter=${JSON.stringify(filterChange)}`) : await fetchRequest.get(`/api/forecastInput/currentforecastdetails`);
      if (!res) {
      if (err2 !== null) {
        handleError(err);
      }
    }
    else if (res !== null) {
      setTeamIdForMember(res?.member?.teamId);
      setTeamIdForOwner(res?.teamForOwner?.id);
      setTeamName(cloudCostOwnerOrAdmin && ![null, undefined, 'null', 'undefined'].includes(sessionStorage['Forecast Team']) ? sessionStorage['Forecast Team'] : cloudEngineer ? res?.teamForMember[0]?.name : sessionStorage['Forecast Team']);
      setTeamsforMember(cloudEngineer && res?.teamForMember ? res?.teamForMember : undefined)
      if (filterChange.name === 'Forecast Team' && filterChange.session) {
        sessionStorage.setItem("Forecast Team", filterChange.values)
      } else {
        sessionStorage.setItem("Forecast Team", cloudEngineer && teamName && !['undefined', 'null', undefined, null, ''].includes(teamName) &&  res?.teamForMember.map((team) => team.name).includes(teamName) ? teamName : cloudEngineer && res?.teamForMember[0]?.name ? res?.teamForMember[0]?.name : undefined)
      }
    }
      if (err1) {
        console.error(err)
      }
      else {
        var prevYear = sessionStorage['Year']
        if (data1?.allYears?.indexOf(sessionStorage['Year']) > 0) {
          if (data1?.allMonths[data1?.allMonths?.indexOf(sessionStorage['Forecast_Month'])] === data1?.firstMonth )
            prevYear =  data1?.allYears[data1?.allYears?.indexOf(sessionStorage['Year'])-1]
        }
        else {
          if (data1?.allMonths[data1?.allMonths?.indexOf(sessionStorage['Forecast_Month'])] === data1?.firstMonth )
            prevYear =  "0"
        }
        var prevMonth = ''
        if (data1?.allMonths?.indexOf(sessionStorage['Forecast_Month']) >= 0)
          prevMonth =  data1?.allMonths?.indexOf(sessionStorage['Forecast_Month']) ? data1?.allMonths[data1?.allMonths?.indexOf(sessionStorage['Forecast_Month'])-1] :  data1?.allMonths[11]
        setCurrentYear(data1?.currentYear);
        // setPreviousYear(data1?.previousYear);
        setFirstMonth(data1?.firstMonth);
        setCurrentMonth(data1?.currentYearAndMonthWithSpend?.monthName);
        // setPreviousMonth(data1?.previousMonth)
        setPreviousYear(prevYear);
        setPreviousMonth(prevMonth)
        setYearAndMonthWithSpend(data1?.currentYearAndMonthWithSpend)
        setYearAndMonthWithForecast(data1?.currentYearAndMonthWithForecast)
      }
      sessionStorage.setItem("Forecast_Month", ![null, undefined, 'null', 'undefined', ''].includes(sessionStorage['Forecast_Month']) ? sessionStorage['Forecast_Month'] : data1?.currentYearAndMonthWithSpend?.["monthName"] && !filterChange ? 
      data1?.currentYearAndMonthWithSpend?.["monthName"] : data1?.currentYearAndMonthWithForecast?.["monthName"] && !filterChange ? data1?.currentYearAndMonthWithForecast?.["monthName"] : filterChange.name === 'Forecast_Month' ? 
      filterChange.values : ![null, undefined, 'null', 'undefined', ''].includes(sessionStorage['Forecast_Month']) ? sessionStorage['Forecast_Month'] : data1?.firstMonth);
      var [err, data] = await fetchRequest.get(`/api/forecast/${dashboardId}`)
      if (err) {
        handleError(err);
      }
      else {
        let newDashboardDef = setDrilldownFilters(applyDefaultFilters(data), location.state);
        newDashboardDef?.avatar ? setAvatar(newDashboardDef?.avatar) : setAvatar(false)
        setFilter2Query(newDashboardDef, [prevMonth], [prevYear])
        setDashboardDef(newDashboardDef);
        // setDashboardDef((draft) => {
        //   setFilter2Query(draft ? draft : newDashboardDef, [prevMonth], [prevYear])
        // })
        initDashboardContext(dispatch, newDashboardDef?.filters, newDashboardDef?.variables);
        setLoading(false);
        setFiltersCleared(false)
      }
    }
    getDashboardDef();
  }, [dashboardId]);

  useEffect(async () => {
    if (filterChange && !filtersCleared) {
      const [err1, data1] = filterChange && (filterChange.name === 'Year' || filterChange.name === 'Forecast_Month') && await fetchRequest.get(`/api/forecastInput/currentforecastdetails?filter=${JSON.stringify(filterChange)}`)
      if (err1) {
        console.error(err1)
      }
      else {
        var prevYear = sessionStorage['Year']
        if (data1?.allYears?.indexOf(sessionStorage['Year']) > 0) {
          if (data1?.allMonths[data1?.allMonths?.indexOf(sessionStorage['Forecast_Month'])] === data1?.firstMonth )
            prevYear =  data1?.allYears[data1?.allYears?.indexOf(sessionStorage['Year'])-1]
        }
        else {
          if (data1?.allMonths[data1?.allMonths?.indexOf(sessionStorage['Forecast_Month'])] === data1?.firstMonth )
            prevYear =  "0"
        }
        var prevMonth = ''
        if (data1?.allMonths?.indexOf(sessionStorage['Forecast_Month']) >= 0)
          prevMonth =  data1?.allMonths?.indexOf(sessionStorage['Forecast_Month']) ? data1?.allMonths[data1?.allMonths?.indexOf(sessionStorage['Forecast_Month'])-1] :  data1?.allMonths[11]
        setCurrentYear(data1?.currentYear);
        setFirstMonth(data1?.firstMonth);
        setCurrentMonth(data1?.currentYearAndMonthWithSpend?.monthName);
        setPreviousYear(prevYear);
        setPreviousMonth(prevMonth)
        setYearAndMonthWithSpend(data1?.currentYearAndMonthWithSpend)
        setYearAndMonthWithForecast(data1?.currentYearAndMonthWithForecast)
      }
      setDashboardDef((draft) => {
        setFilter2Query(draft, [prevMonth], [prevYear])
        initDashboardContext(dispatch, current(draft?.filters), current(draft?.variables));
      })
      setLoading(false);
      setFiltersCleared(false)
    }
  },[filterChange, filtersCleared])

  const selectFilter = (selectedFilter) => {
    setDashboardDef((draft) => {
      let filter = draft?.filters?.find(f => f.name === selectedFilter.name);
      if (filter)
        filter.selected = true;
    });
  }
  const deleteFilter = (deletedFilter) => {
    setDashboardDef((draft) => {
      deleteSelectedFilter(draft, deletedFilter)
      setDashboardContextFilters(dispatch, current(draft?.filters));
    });
  }
  const setFilter = (selectedFilter) => {
    // const firstMonth = dashboardDef.variables["firstMonth"] || 'Jan';
    // const currentMonth = dashboardDef.variables["currentMonth"] || 'Dec';
    // const mthArray = getFiscalMonthsArray(firstMonth);
    let fil = selectedFilter
    // if (fil.name === "Forecast_Month" && fil.session) {
    //   if (selectedFilter.values)
    //     fil.values = mthArray.slice(0, mthArray.indexOf(selectedFilter.values[0]) + 1)
    //   else
    //     fil.values = mthArray.slice(0, mthArray.indexOf(currentMonth) + 1)
    // }

    if (fil?.values?.length === 0) {
      setDashboardDef((draft) => {
        deleteSelectedFilter(draft, selectedFilter)
        let filter = draft?.filters?.find(f => f.name === selectedFilter.name);
        if (filter)
          filter.selected = true;
        setDashboardContextFilters(dispatch, current(draft?.filters));
      });
    }
    else {
      setFilterChange(fil)
      setDashboardDef((draft) => {
        setSelectedFilter(draft, fil?.values, fil?.name, fil?.dimension, fil?.operator)
        setDashboardContextFilters(dispatch, current(draft?.filters));
      });
    }
  }

  if (loading) {
    return <YASkeleton variant="dashboard-loading" />;
  }

  // if (!loading && ([null, undefined, 'null', 'undefined'].includes(currentYearAndMonthWithSpend) && ([null, undefined, 'null', 'undefined'].includes(currentYearAndMonthWithForecast) && (dashboardDef?.name !== "teams-admin" )) && !filterChange)) {
  //   return (
  //     <div>
  //       {(teamForMember?.length === 0 && (currentYearAndMonthWithSpend?.length > 0) || currentYearAndMonthWithForecast?.length > 0) ? <Alert severity="warning">No team is assigned to you.Please contact your admin</Alert> : 
  //       <MDBox p={3} pt={10} sx={{ height: '100%' }}>
  //         <MDBox display="flex" alignItems="center" justifyContent="center" minHeight="calc(100vh - 300px)">
  //           <EmptyState
  //               // yearFilter={yearFilter} 
  //               // monthFilter={monthFilter}
  //               size="large"
  //               image={new_item_img}
  //               title={"No Data"}
  //               description={""}
  //               // actions={renderAddButton}
  //               />
  //           </MDBox>
  //       </MDBox>
  //       }
  //     </div>
  //   );
  // }

  const notSession = dashboardDef?.filters.filter( fil => {
    if (!fil.session) {
      return true
    }
  })
  const dashboardItem = (item) => {
    return (
      <div key={item.id}>
        <ChartRenderer title={item.name} subtitle={item.desc} chartHelpContextKey={item.helpContextKey} vizState={item.vizState} vizOptions={item.vizOptions} currentYear={currentYear} currentMonth={currentMonth} previousYear={previousYear} previousMonth={previousMonth} firstMonth={firstMonth} teamForMember={teamForMember} role={userInfo?.role} />
      </div>
    )
  };


  const { displayName, desc, layouts, widgets, helpContextKey, headerShadow } = dashboardDef;
  return (
    <MDBox bgColor={colors.dashboardBackground} minHeight="calc(100vh - 56px)" paddingBottom={{ lg: 0, md: 6, sm: 6, xs: 6 }}>
      <PageHeader title={displayName} subtitle={avatar && ![null, undefined, 'null', 'undefined'].includes(sessionStorage['Forecast Team']) ? `Overview for the Team` : desc } noTeam={cloudEngineer && teamForMember.length <= 0 ? true : false} pageHelpContextKey={helpContextKey} dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={() => setFiltersCleared(true)} sessionFilters={true} memberId={userInfo.sub} avatar={avatar} avatarCont={sessionStorage['Forecast Team']} teamForMember={teamForMember} role={userInfo?.role} headerShadow={widgets ? true : headerShadow ? headerShadow :false} />
      {cloudEngineer && teamForMember.length <= 0 && <Alert sx={{mt: 1}}severity="info">No team is assigned to you. Please contact your admin.</Alert>}
      {!((cloudEngineer && teamForMember.length <= 0 ? true : false) && displayName === 'Team Showback') && <>
      {notSession.length > 0 && <FilterContainer dashboardDef={dashboardDef} selectFilter={selectFilter} setFilter={setFilter} deleteFilter={deleteFilter} clearFilters={() => setFiltersCleared(true)} sessionFilters={false} role={userInfo?.role} teamForMember={teamForMember} headerShadow={widgets ? true : headerShadow ? headerShadow :false}/>}
      <MDBox px={1} pb={2}>
        <ResponsiveDashboardContainer1 dashboardLayouts={layouts}>
          {widgets.map(dashboardItem)}
        </ResponsiveDashboardContainer1>
      </MDBox></>}
    </MDBox>
  );
};

const ForecastPageWithContext = () => <DashboardContextProvider><ForecastPage /></DashboardContextProvider>

export default AnimatedRoute(ForecastPageWithContext);
