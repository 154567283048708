import MDBox from 'components/MDBox';
import _ from 'lodash';
import DataTable from 'components/DataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, Menu, MenuItem, Tooltip, IconButton } from '@mui/material';
import { useEffect, useMemo, useState, useContext } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import numeral from 'numeral';
import { useAppController } from 'context';
import { useImmer } from 'use-immer';
import FilterChip from 'components/FilterChip';
import * as XLSX from 'xlsx';
import SolutionOfferingRuleForm from './components/SolutionOfferingRuleForm';
import { parseJsonString } from "utils";
// import { getPublishedByMonth } from "utils"
// import{backgroundProcessCheck} from '../../../../utils'

const FilterDropdown = (props) => {
  const { formId, filter, onFilterChange } = props;
  const { name, displayName, dataSource, values } = filter;
  const [options, setOptions] = useState(dataSource?.type === 'static' ? dataSource.data : []);
  const [loading, setLoading] = useState(dataSource?.type !== 'static');

  useEffect(() => {
    async function getOptions() {
      setLoading(true);
      const [error, data] = await fetchRequest.get(`/api/assetRules/${formId}/${name}`);
      if (error)
        console.error(error)
      setOptions(data || []);
      setLoading(false);
    }
    if (dataSource?.type !== 'static') getOptions();
  }, [name]);

  const handleOnFilterChange = (selectedFilter) => {
    onFilterChange({
      name: selectedFilter.name,
      type: selectedFilter.type,
      operator: selectedFilter.operator,
      values: selectedFilter.values.map(v => options.find(o => o.label === v)?.value)
    });
  }

  const sOptions = useMemo(() => options?.map(o => o.label), [options]);
  const filterValue = { name, operator: "eq", values: values?.map(v => options?.find(o => o.value === v)?.label) };
  return (
    <FilterChip loading={loading} dismissible={false} openOnMount={false} key={name} dataType={"select"} name={name} field={displayName} filterValue={filterValue} options={sOptions} onFilterSave={handleOnFilterChange} />
  );
};
let linkFieldAccessor
let linkFieldColumns = []

const toSolutionOfferingColumn = [ 
  // { Header: "Solution Offering", accessor: "toSolutionOffering", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{_.startCase(value)}</MDTypography> } }, 
  { Header: "Rule", accessor: "solutionOfferingRule", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
  { Header: "Portion", accessor: "portion", disableSorting: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{`${numeral(value).format('0.00')}%`}</MDTypography> } },
]
const getTablecol = async () => {
  let [_err, data] = await fetchRequest.get(`/api/solutionOfferingRules/SOFields`);
  if (_err) {
    console.error(_err);
    return [];
  }
  return data.fields;
};

const filterTypeLabels = {
  eq: "is equal to",
  equals: "is equal to",
  ne: "is not equal to",
  notEquals: "is not equal to",
  contains: "contains",
  notContains: "does not contain",
  startsWith: "starts with",
  endsWith: "ends with",
  set: "is set",
  notSet: "is not set",
  gt: "is greater than",
  gte: "is greater than or equal to",
  lt: "is less than",
  lte: "is less than or equal to",
  between: "is in between",
  notBetween: "is not in between",
  before: "is before",
  after: "is after",
};

const getFilterDescription = (field, dataType = "string", operator, values) => {
  let valueDescription = "";

  if (Array.isArray(values) && values?.length > 1) {
    if (["between", "notBetween"].includes(operator)) {
      if (dataType === "date") {
        valueDescription = `${moment(values[0]).format("MMM DD YYYY")} and ${moment(values[1]).format("MMM DD YYYY")}`;
      } else {
        valueDescription = `${values[0]} and ${values[1]}`;
      }
    } else {
      valueDescription = values.slice(0, values.length - 1).join(", ") + " or " + values[values.length - 1];
    }
  } else if (Array.isArray(values) && values?.length === 1) {
    if (dataType === "date") {
      valueDescription = moment(values).format("MMM DD YYYY");
    } else {
      valueDescription = values[0];
    }
  } else if (["set", "notSet"].includes(operator)) {
    valueDescription = "";
  } else {
    valueDescription = values;
  }
  return `Solution Offering ${field} ${filterTypeLabels[operator]} ${valueDescription}`;
};
const GetRulePopup = (props) => {
  const { condition } = props;
  const [assetList, setAssetList] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchAssets = async () => {
      let [_err, data] = await fetchRequest.get(`/api/dataflow/assets/list`);
      if (_err) {
        console.error(_err);
      } else {
        setAssetList(data);
      }
    };
    
    fetchAssets();
  }, []);

  useEffect(() => {
    const fetchAndProcessData = async () => {
      let newRows = [];
      for (let item of condition.toSolutionOfferings) {
          const conditionsArr = JSON.parse(item.solutionOfferingRule);
          const conditions = conditionsArr?.map((c) => 
            getFilterDescription(c.field, c.type, c.operator, c.value)
          );
          let row = { ...item, solutionOfferingRule: conditions };
          newRows.push(row);
        // }
      }
      setRows(newRows);
    };

    if (assetList.length > 0) {
      fetchAndProcessData();
    }
  }, [assetList, condition.toAssets]);
  return (
    <MDBox pb={3} minWidth="800px" width="100%">
      <DataTable
        variant="tile"
        table={{ columns: toSolutionOfferingColumn, rows: rows.length > 0 ? rows : condition.toSolutionOfferings }}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
        entriesPerPage={false}
        canSearch={false}
      />
    </MDBox>
  );
};

const GetSortPopup = (props) => {
  const { columns, yearFilter, monthFilter, rowValue, masterDef, chargeBack, handleError, setRefresh } = props
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const { onDialogClose } = useContext(YADialogCustomFormContext);
  const { showAlert, showSnackbar } = useYADialog();

  const handleMoveUp = (r, topData, rows, data, index) => {
    const temp = r.sequence;
    r.sequence = topData.sequence;
    topData.sequence = temp;
    rows[index].sequence = r.sequence;
    rows[index -1].sequence = topData.sequence;
    setData(data);
  };

  const handleMoveDown = (r, bottomData, rows, data, index) => {
    const temp = r.sequence;
    r.sequence = bottomData.sequence;
    bottomData.sequence = temp;
    rows[index].sequence = r.sequence;
    rows[index + 1].sequence = bottomData.sequence;
    setData(data);
  };

  useEffect(async() => {
    let [err, data] = await fetchRequest.post(`/api/solutionOfferingRules/list/${yearFilter}/${monthFilter}`, {fromAsset: rowValue.fromAsset});
    if (err) {
      handleError(err);
    } else {
      if (data && Array.isArray(data) && data.length > 0) {
        setRows(buildRows(masterDef.pkColumn || 'id', data, null, null, null, chargeBack, handleMoveUp, handleMoveDown));
      } else {
        setRows([])
      }
    }
  },[masterDef, rowValue, chargeBack, yearFilter, monthFilter])
  
  const onApplyRules = async() => {
    let [err, response] = await fetchRequest.post(`/api/assetRules/${yearFilter}/${monthFilter}/applySort`, {data: data});
    if (err) {
      if (err.data?.message) {
          onDialogClose();
          setRefresh(Math.random());
          showSnackbar(err.data?.message, "error")
      } else {
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    } else {
      onDialogClose();
      setRefresh(Math.random());
      showSnackbar(response.message, "success");
    }
  }

  const applyBtnRenderer = () => {
    return (
      <>
        <MDButton disabled={chargeBack || data.length === 0} onClick={() => onApplyRules()} color={ "info"} sx={{mr:5}} variant="contained"> {`Apply Rules`}</MDButton>
      </>
    );
  };
  return (
    <MDBox  pb={3}
    minWidth="800px"
    width="100%"
    >
    {
      <DataTable
      primaryRender={applyBtnRenderer()}
      table={{ columns, rows }}
      showTotalEntries={false}
      disableSorting={true}
      isSorted={false}
      noEndBorder
      entriesPerPage={false}
      canSearch={true}
    />
    }
  </MDBox>
  )
}

const buildColumns = (masterDef, defaultDateFormat, handlePopup, chargeBack, popup) => {
  linkFieldAccessor = []
  const columns = [];
  if (!masterDef.readonly && !chargeBack && !popup)
  columns.push({
    Header: 'Actions',
    accessor: 'actions',
    align: 'left',
    disableSorting: true,
    disableFilters: true,
  });
  if (!masterDef.readonly && !chargeBack && popup)
  columns.push({
    Header: 'Sort Actions',
    accessor: 'sortActions',
    align: 'left',
    disableSorting: true,
    disableFilters: true,
  });
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    masterDef.fields?.filter(f => !f.hidden)?.forEach((f) => {
      let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
      let accessor = f.schemaName;
    //   if (f.type === 'dropdown' && !f.dataSource.consumptionAsset) {
    //     accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
    //   } need to see with kandan
      if (f.type === "rule") {
        accessor = "ruleStr"
      }
    //   if (f.type === "toAssetrule") {
    //     accessor = "toAssetRuleStr"
    //   }
      col['Header'] = f.displayName;
      col['accessor'] = accessor;
      col['Cell'] = ({ cell: { value }, row }) => {
        if (f.type === "currency")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
        else if (f.type === "datepicker")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
        else if (f.type === 'rule')
          return (value || "") !== "" ? <Tooltip placement="top" title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null
        else if (f.type === 'toSolutionOfferingRule')
          return (value || "") !== "" ? <Tooltip placement="top" sx={{":hover": {cursor: "pointer"}}} onClick={() => handlePopup(row.original.toSolutionOfferingRule)} title="Condition"><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null
        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{(f.name == 'fromAsset') ? _.capitalize(value) : value}</MDTypography>
      };
      col['dataType'] = f.filterType || f.type
      col['disableFilters'] = f.disableFilters || false
      col['disableSorting'] = f.disableSorting || false
      f.dataSource && f.dataSource.linkField != undefined ? linkFieldColumns.push(col) : columns.push(col);
      if (f.dataSource) {
        let columcreat = (f) => {
          let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
          let accessor = f.schemaName;
          if (f.type === 'dropdown') {
            accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
          }
          col['Header'] = f.displayName;
          col['accessor'] = accessor;
          col['Cell'] = ({ cell: { value } }) => {
            if (f.type === "currency")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
            else if (f.type === "datepicker")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
            return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value}</MDTypography>
          };
          col['dataType'] = f.filterType || f.type
          col['disableFilters'] = f.disableFilters || false
          linkFieldColumns.push(col);
          linkFieldAccessor.push(accessor)
          if (f.dataSource) {
            linkFieldCheck(f)
          }
        }
        let linkFieldCheck = (f) => {
          if (f.dataSource.linkField) {
            columcreat(f.dataSource.linkField)
          }
        }
        f.dataSource.linkField ? linkFieldAccessor.push(accessor) : linkFieldAccessor
        linkFieldCheck(f)
        if (linkFieldColumns.length > 0) {
          linkFieldColumns = linkFieldColumns.reverse()
          linkFieldColumns.forEach(item => { columns.push(item) })
          linkFieldColumns = []
        }
      }
    });
  }
  return columns;
};

const buildRows = (pkColumn, data, onEdit, onDelete, onSort, chargeBack, onMoveUp, onMoveDown) => {
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r, i) => {
      let row = {};
      Object.keys(r).forEach((k) => {
        row[k.replace(/\./g, '__')] = r[k];
      });
      if (linkFieldAccessor.length > 0) {
        let nameSet = row[linkFieldAccessor[0]].split(" | ").reverse()
        for (let i = 0; i < linkFieldAccessor.length; i++) {
          row[linkFieldAccessor[i]] = nameSet[i]
        }
      }

      r?.taxonomy === true || chargeBack ? null : row['actions'] =
        (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
            <Tooltip title="Edit" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onEdit(r[pkColumn])}>
                <Icon fontSize='small'>edit</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onDelete(r[pkColumn])}>
                <Icon fontSize='small' color="error">delete</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={() => onSort(r)}>
                <Icon fontSize='small' color="info">swap_vert</Icon>
              </IconButton>
            </Tooltip>
            {/* <MDTypography
              display="flex"
              alignItems="center"
              component="a"
              href="#"
              onClick={() => onEdit(r[pkColumn])}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              <Icon fontSize="small">edit</Icon>&nbsp;Edit
            </MDTypography>
            <MDTypography
              display="flex"
              alignItems="center"
              ml={3}
              component="a"
              href="#"
              onClick={() => onDelete(r[pkColumn])}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
              &nbsp;Delete
            </MDTypography>
            <MDTypography
              display="flex"
              alignItems="center"
              ml={3}
              component="a"
              href="#"
              onClick={() => onSort(r)}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              <Icon fontSize="small" color="info">
                swap_vert
              </Icon>
              &nbsp; Sort
            </MDTypography> */}
          </MDBox>
        );

        r?.taxonomy === true || chargeBack || data.length < 2 ? null : row['sortActions'] =
        (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
            <Tooltip title="Move Up" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={ i === 0 ? false : () => onMoveUp(r, data[i -1], rows, data, i)}>
                <Icon fontSize="small" color={i === 0 ? "disabled" : "info"}> arrow_circle_up</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Move Down" placement="top">
              <IconButton sx={{ padding: 0, paddingRight: 1 }} onClick={ i === data.length - 1 ? false : () => onMoveDown(r, data[i + 1], rows, data, i)}>
                <Icon fontSize="small" color={i === data.length - 1 ? "disabled" : "info"}> arrow_circle_down</Icon>
              </IconButton>
            </Tooltip>
            {/* <MDTypography
              display="flex"
              alignItems="center"
              component="a"
              href="#"
              onClick={ i === 0 ? false : () => onMoveUp(r, data[i -1], rows, data, i)}
              variant="caption"
              color={i === 0 ? "disabled" : "text"}
              fontWeight="medium"
            >
              <Icon fontSize="small" color={i === 0 ? "disabled" : "info"}> arrow_circle_up</Icon>&nbsp;Move Up
            </MDTypography>
            <MDTypography
              display="flex"
              alignItems="center"
              ml={3}
              component="a"
              href="#"
              onClick={ i === data.length - 1 ? false : () => onMoveDown(r, data[i + 1], rows, data, i)}
              variant="caption"
              color={i === data.length - 1 ? "disabled" : "text"}
              fontWeight="medium"
            >
              <Icon fontSize="small" color={i === data.length - 1 ? "disabled" : "info"}> arrow_circle_down</Icon>&nbsp;Move Down
            </MDTypography> */}
          </MDBox>
        );
      rows.push(row);
    });
  }
  return rows;
};

const filtersInitiaized = (filters) => {
  let initiaized = false;
  filters?.forEach(f => {
    if (f.values && Array.isArray(f.values) && f.values?.length > 0) {
      initiaized = true;
      return;
    }
  });
  return initiaized;
}

// const buildPopupColumns = (masterName) => {
//   let columns = [
//     { Header: masterName, accessor: "masterRecordId", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="medium" color="dark">{value}</MDTypography> } },
//     { Header: "Message", accessor: "message", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" alignItems="center" fontWeight="small" color={value.includes("success") ? "success" : "error"}>{value}</MDTypography> } }
//   ]
//   return columns;
// }

// const buildPopupRows = (masterName, data) => {
//   let rows = data.map(item => {
//     return {
//       "masterRecordId": item[masterName],
//       "message": item["message"],
//     }
//   })
//   return rows
// }


const SolutionOfferingMapping = (props) => {
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const { masterId, uploadName,  yearFilter, monthFilter, yearFilterName, monthFilterName, chargeBackMonthly, setAction, setSubCategoryFilter } = props
  const [masterDef, setMasterDef] = useImmer(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [typeFilter,] = useState(uploadName);
  const [openMenu, setOpenMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([])


  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
  let filters = []
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: filters });

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }

  const chargeBack = _.find(chargeBackMonthly , { monthNameId : monthFilter, yearNameId: yearFilter }) ? true : false

//   useEffect(async () => {
//     if (yearFilter && monthFilter) {
//         const response = await getPublishedByMonth(yearFilter, monthFilter)
//         if (response) {
//             setPublished(true)
//         } else {
//             setPublished(false)
//         }
//     }
// }, [yearFilter, monthFilter])
  // const handlePopup = () => {
  //   setpopup(false)
  //   setRefresh(Math.random());
  // }
  const handleClose = () => {
    setRefresh(Math.random());
  };

  const handleEdit = (pkId) => {
    showCustomForm(`Edit Solution Rule`, () => <SolutionOfferingRuleForm onClose={handleClose} mode="edit" pkId={pkId} getTablecol={getTablecol} filterTypeLabels={filterTypeLabels} getFilterDescription = {getFilterDescription}/>, handleClose, "edit", pkId, "md");
  };


  const deleteMaster = async (pkId) => {
    const [err, data] = await fetchRequest.delete(`/api/solutionOfferingRules/${pkId}`);
    if (err) {
      if (err.data?.message) {
        handleClose();
        showSnackbar(err.data?.message, "error")
      } else {
        handleClose();
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data deleted successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
      }
  };

  const handleDeleteSuccess = (pkId) => {
    deleteMaster(pkId);
  };

  const handleDelete = (pkId) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
  };

  const handleSort = async (rowValue) => {
    const columns = buildColumns(masterDef, defaultDateFormat, handlePopup, chargeBack, true);
    handleSortPopup(columns, yearFilter, monthFilter, rowValue, masterDef, chargeBack, handleError)
  };

  const deleteMasterMultipleRecords = async (selectedRows) => {
    const [err, data] = await fetchRequest.post(`/api/solutionOfferingRules/deleteMultiple`, selectedRows);

    if (err) {
      if (err.data?.message) {
        handleClose();
        setRefresh(Math.random());
        showSnackbar(err.data?.message, "error")
      } else {
        handleClose();
        setRefresh(Math.random());
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    }
    else if (data && data.result === true) {
      showSnackbar('Data deleted successfully', 'success');
      handleClose()
      // setRefresh(Math.random());

    }
  }
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
    // showCustomDrawer('', () => <UserInfoDrawer />);
  };

  const handleDeleteMultiple = (selectedRows) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => deleteMasterMultipleRecords(JSON.stringify(selectedRows)));
  };

  const getAppliedFilters = () => {
    if (!masterDef.filters || masterDef.filters.length === 0)
      return null;

    return {
      "filters": JSON.stringify(
        masterDef.filters?.map(f => ({
          name: f.name,
          operator: f.operator,
          value: f.values
        })) || []
      )
    };
  };

  const handlePopup = (condition) => {
    const parsedCondition = parseJsonString(condition)
    showCustomForm(
      "Solution Offering",
      () => <GetRulePopup condition={parsedCondition} />,
      null,
      null,
      null,
      '500px'
  );
  }

  const handleSortPopup = (columns, yearFilter, monthFilter, rowValue, masterDef, chargeBack, handleError) => {
    showCustomForm(
      "Rule Sort",
      () => <GetSortPopup columns={columns} yearFilter={yearFilter} monthFilter={monthFilter} rowValue={rowValue} masterDef={masterDef} chargeBack={chargeBack} handleError={handleError} setRefresh={setRefresh}/>,
      null,
      null,
      null,
      'lg'
    );
  }

  useEffect(() => {
    async function getMasterDef() {
      let [err, data] = await fetchRequest.get(`/api/solutionOfferingRules/SORuleDef`);
      if (err) {
        handleError(err);
      } else {
        data.yearNameId = yearFilter;
        data.monthNameId = monthFilter;
        setMasterDef(data);
        // if (data.filters && filtersInitiaized(data.filters))
        //   setFilters(data.filters)
        setColumns(buildColumns(data, defaultDateFormat, handlePopup, chargeBack));
      }
    }
    getMasterDef();
  }, [masterId]);

  useEffect(() => {
    async function getList() {
      setLoading(true);
      const appliedFilters = getAppliedFilters();
      let [err, data] = await fetchRequest.post(`/api/solutionOfferingRules/list/${yearFilter}/${monthFilter}`, appliedFilters);
      if (err) {
        handleError(err);
      } else {
        if (data && Array.isArray(data) && data.length > 0) {
          setList(data)
          setRows(buildRows(masterDef.pkColumn || 'id', data, handleEdit, handleDelete, handleSort, chargeBack));
          setStep('LOADED');
        } else {
          setRows([]);
          setStep('EMPTY');
        }
      }
      setLoading(false);
    }
    if (masterDef) {
      getList();
    }
  }, [masterId, masterDef, chargeBack, refresh]);

  if (step === 'LOADING') {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const { displayName, singularDisplayName, desc, message, canFilter } = masterDef;

  const handleAddButtonClick = () => {
    // showForm(`New ${singularDisplayName || displayName}`, masterDef, handleClose);
    showCustomForm(`New ${singularDisplayName || displayName}`, () => <SolutionOfferingRuleForm onClose={handleClose} yearNameId={yearFilter} monthNameId={monthFilter} getTablecol={getTablecol} filterTypeLabels={filterTypeLabels} getFilterDescription = {getFilterDescription} />, handleClose, null, null, 'md');
    handleCloseMenuItem();
  };

  //   const handleUploadButtonClick = async () => {
  //     let bgData = await backgroundProcessCheck();
  //     if(bgData.length>0)
  //     {
  //       showAlert(bgData[0],bgData[1],bgData[2],bgData[3]);
  //     }else
  //     {
  //       showUploadDialog("Data Upload for " + uploadName, { uploadType: uploadType }, handleUploadDialogClose);
  //       handleCloseMenuItem();
  //     }
  //   }

  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  //   const handleUploadDialogClose = (uploadSuccess) => {
  //     if (uploadSuccess) {
  //       setRefresh(Math.random());
  //     }
  //   };

  const handleOnFilterChange = (selectedFilter) => {
    setMasterDef((draft) => {
      let filter = draft.filters?.find(f => f.name === selectedFilter.name);
      filter.operator = selectedFilter.operator;
      filter.values = selectedFilter.values;
    });
  };
  const handleDownload = async () => {
    let newData = list.map(item => {
      return {
        "From Asset": item["fromAsset"],
        "Rule": item["rule"],
        "Solution Rule": item['toSolutionOfferingRule'],
        "Sequence": item["sequence"]
      }
    })
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(newData)
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, `${masterId}.xlsx`)
      await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded solution rules from ${yearFilterName} - ${monthFilterName}}` })
    handleCloseMenuItem();
  };

  const renderPrimaryActions = () => {
    return (
      <>
        <MDBox color="text" pt={0} mt={0} display="flex" flexDirection="row">
          <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            {<>
              {step !== 'EMPTY' &&<MenuItem key={'download'} onClick={handleDownload}>{"Download"}</MenuItem>}
              <MenuItem key={'upload'} onClick={() => { setAction('upload'); setSubCategoryFilter('Solution Rule')}}>{"Upload"}</MenuItem>
              <MenuItem key={'copy'} onClick={ () => {setAction('copy'); setSubCategoryFilter('Solution Rule')}}>{"Copy"}</MenuItem>
            </>
            }
          </Menu>
          <MDButton disabled = {chargeBack} data-testid={"addnew"} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
            Add New
          </MDButton>
          &nbsp;
          <MDBox mt={0} mr={1} pt={0}>
            <MDButton
              // size="medium"
              disableRipple
              color="dark"
              variant="text"
              onClick={handleOpenMenu}
              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
              iconOnly
            >
              <Icon px={0} py={0}>more_horiz</Icon>
            </MDButton>
          </MDBox>

        </MDBox>

      </>
    );
  };
  const renderPrimaryActions1 = () => {
    return (
      <>
        <MDButton
          data-testid={"addnew"}
          variant="outlined"
          color="info"
          disabled = {chargeBack}
          startIcon={<Icon>add</Icon>}
          onClick={handleAddButtonClick}
        >
          Add New
        </MDButton>
      </>
    );
  };

  //   const renderAddButton = () =>
  //      (
  //       <MDButton
  //         data-testid = {"Upload".toLowerCase().replaceAll(' ', '')}
  //         variant="gradient"
  //         color="info"
  //         startIcon={<Icon>cloud_upload</Icon>}
  //         onClick={handleUploadButtonClick}>
  //         Upload
  //       </MDButton>
  //       // <MDButton
  //       //   variant="outlined"
  //       //   color="info"
  //       //   startIcon={<Icon>add</Icon>}
  //       //   onClick={handleAddButtonClick}
  //       // >
  //       //   Add New
  //       // </MDButton>
  //     );
  const renderFilters = () => {
    return (
      <>
        {masterDef.filters?.map((f) => (
          <FilterDropdown key={f.name} formId={masterId} filter={f} onFilterChange={handleOnFilterChange} />
        ))}
      </>
    )
  }

  const defaultFilteresInitiaized = filtersInitiaized(masterDef?.filters);

  return (
    <>
      <PageHeader
        title={displayName}
        subtitle={desc}
        message={message}
        hideBreadcrumbs={true}
        primaryActionComponent={renderPrimaryActions}
        anchor={displayName}
      />
      {/* <MDBox display="flex" sx={{marginBottom: "10px"}}>
        <MDButton data-testid = {uploadName?.toLowerCase().replaceAll(' ', '')} sx={(theme) => tabStyles(theme, { selected: typeFilter === uploadName })} onClick={() => setTypeFilter(uploadName)}>{uploadName.toUpperCase()}</MDButton>
      </MDBox> */}

      {typeFilter === uploadName && <MDBox p={3} pt={1}>
        {step === 'EMPTY' && (masterDef.filters?.length === 0 || !defaultFilteresInitiaized) && (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 300px)"
          >
            <EmptyState
              size="large"
              image={new_item_img}
              title={`No ${displayName} Yet`}
              description={
                !masterDef.readonly
                  ? `Click on the '+ Add New' button to add a new ${(
                    singularDisplayName || displayName
                  ).toLowerCase()}.`
                  : undefined
              }
              actions={renderPrimaryActions1}
            />
          </MDBox>
        )}
        {(step === 'LOADED' || (step === 'EMPTY' && masterDef.filters?.length > 0) && defaultFilteresInitiaized) &&
          <>
            <Card sx={{ height: '100%' }} px={0}>
              <DataTable
                containerMaxHeight={500}
                table={{ columns, rows }}
                showTotalEntries={true}
                isSorted={false}
                newStyle1={true}
                isSelectable={masterDef.readonly || masterDef.taxonomy || chargeBack ? false : true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                filtersComponent={renderFilters()}
                canFilter={canFilter}
                loading={loading}
                deleteMultiple={masterDef.readonly || masterDef.taxonomy ? false : true}
                onDeleteMultiple={handleDeleteMultiple}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
              />

              {/* <Modal open={popup} onClose={handlePopup}>
                <MDBox pt={20} pl={50} pr={50} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                  <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                    <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDBox>
                        <MDTypography variant="h6" component="span" color="text">
                          {masterDef.displayName} Deletion
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex">
                        <IconButton onClick={handlePopup} title="Close">
                          <Icon>close</Icon>
                        </IconButton>
                      </MDBox>
                    </MDBox>
                    <DataTable
                      table={{ columns: popupColumns, rows: popupRows }}
                      containerMaxHeight={474}
                      showTotalEntries={true}
                      entriesPerPage={true}
                    >
                    </DataTable>
                  </Card>
                </MDBox>
              </Modal> */}
            </Card>
          </>
        }

      </MDBox>}
    </>
  );
};

export default AnimatedRoute(SolutionOfferingMapping);
