import { NotificationType } from "constants";

export const getTargetRouteForNotification = (notificationType, { details }) => {
    if (notificationType === NotificationType.Alert && details.alertId) {
        return `/alerting/${details.alertId}/alert-details`;
    }
    else if (notificationType === NotificationType.Interface) {
        return `/admin/integrations`;
    }

    return null;
};