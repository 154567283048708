import { Card, MenuItem, Modal, Icon, IconButton, TextField, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import { useCallback, useMemo, useState } from "react";
import numeral from "numeral";
import MDButton from "components/MDButton";
import fetchRequest from "utils/fetchRequest";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import StrategySelectionSidebar from "pages/Dataflow/components/StrategySelectionSidebar";
import moment from 'moment';
import { useAppController } from "context";
import { useImmer } from "use-immer";
import { normalizeCurrency } from "utils/table";
import{ backgroundProcessCheck } from 'utils'
// import useHandleError from "hooks/useHandleError";

// const handleError = useHandleError();

const AddCostPoolRule = (props) => {
  const { typeFilter, yearFilter, monthFilter ,setRefresh, chargeBackMonthly} = props;

  const [mappingType, setMappingType] = useState(typeFilter === "Spend" ? undefined : 3)
  const chargeBack = _.find(chargeBackMonthly , { monthNameId : monthFilter, yearNameId: yearFilter}) ? true : false 

  let mappingTypes = []
  if (typeFilter === "Spend"){
    mappingTypes = [
      { value: 1, displayName: "Account, Expense Type" },
      { value: 2, displayName: "Account, Expense Type & Cost Center" },
      { value: 3, displayName: "Account, Expense Type, Cost Center & Vendor" },
    ]
  }
  else{
    mappingTypes = [
      { value: 3, displayName: "Account, Expense Type, Cost Center & Vendor" },
    ]
  }
  

  const selectedMappingTypeOption = mappingTypes.find(o => o.value === mappingType);
  
  const handleChangeMappingType = (value) => {
    setMappingType(value);
  }

  const strategyItemStyles = ({ palette: { white, info } }) => ({
    display: "flex",
    flexDirection: "column",
    px: 2,
    py: 1.5,
    m: 0.8,
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #ddd",
    "& .title": {
      marginBottom: 1
    },
    "&:hover": {
      backgroundColor: info.main
    },
    "&:hover .title, &:hover .subtitle": {
      color: white.main,
    }
  });
  if (typeFilter === "Spend")
    return (
      <>
        {
          !mappingType && (
            <MDBox height="100%" px={3} pt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <MDTypography variant="subtitle1" fontWeight="light" color="text" component="span" mb={3} mt={props.mt}>
                Choose a mapping strategy
              </MDTypography>
              <MDBox display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" px={3} >
                {
                  mappingTypes?.map((option) => {
                    return <MDBox key={`l_${option.value}`}
                      sx={(theme) => strategyItemStyles(theme)}
                      onClick={() => {
                        setMappingType(option.value)
                      }}
                    >
                      <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                      <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{option.displayName}</MDTypography>
                    </MDBox>
                  })
                }
              </MDBox>
            </MDBox>
          )
        }
        {
          mappingType && <FetchData {...props} mappingTypes={mappingTypes} chargeBack={chargeBack} mappingType={mappingType} onChangeMappingType={handleChangeMappingType} selectedMappingTypeOption={selectedMappingTypeOption} typeFilter={typeFilter} setRefresh={setRefresh}/>
        } 
      </>
    )
  else 
  return (
    <>
      {
        mappingType !== undefined && <FetchData yearFilter={yearFilter} chargeBack={chargeBack} monthFilter={monthFilter} mappingTypes={mappingTypes} mappingType={3} typeFilter={typeFilter} setRefresh={setRefresh}/>
      } 
    </>
  )
}

const FetchData = (props) => {
  const { yearFilter, monthFilter, mappingType, typeFilter , setRefresh, chargeBack } = props;
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });


  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }
  let url
  if (typeFilter === "Spend") {
    url = "/api/dataflow/costPoolRules"
    if (mappingType === 1)
      url = "/api/dataflow/costPoolRules1"
    if (mappingType === 2)
      url = "/api/dataflow/costPoolRules2"
  }
  else
    url = "/api/dataflow/costPoolRulesBudget"
  const { response: data, error: _err, loading: loading, reloadData } = useFetchRequest(`${url}/${yearFilter}/${monthFilter}`);

  if (loading === false && data === null) {
    return (
      <div>
        no data
      </div>
    );
  }
  if (_err)
    console.error(_err)
  return (
    <MDBox>
      {
        loading && <YASkeleton variant="loading" />
      }
      {
        !loading && <ShowData {...props} data={data} reloadData={reloadData} filtersState={filtersState} chargeBack={chargeBack} handleOnFiltersStateUpdate={handleOnFiltersStateUpdate}  typeFilter={typeFilter} setRefresh={setRefresh}/>
      }
    </MDBox>
  )
}

const ShowData = (props) => {
  const { data, yearFilter, monthFilter, reloadData, mappingType, onChangeMappingType, mappingTypes, selectedMappingTypeOption, filtersState, handleOnFiltersStateUpdate, containerHeight, typeFilter, chargeBack } = props;
  const [showOptions, setShowOptions] = useState(false);
  const { showSnackbar, showAlert } = useYADialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [costPool, setCostPool] = useState("")
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [glRows, setGLRows] = useState([]);

  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  // let ce = []
  // let ceu = []
  let costElementsRemaining = []

  if (typeFilter === "Spend")
  {
    const handleOnOptionsClick = () => {
      setShowOptions(true);
    }
  
    const handleOnOptionsClose = () => {
      setShowOptions(false);
    }
  
    const handleOnOptionSelection = (value) => {
      if (onChangeMappingType)
        onChangeMappingType(value);
    }
  
    const handleOnUpdate = useCallback(({ selected }) => {
      setSelectedRows(selected)
    }, [])
  
    const handleOnSelectionClearClick = () => {
      setSelectedRows([])
      setRefresh(Math.random())
    }
  
    const handleDialogOpen = () => {
      setDialogOpen(false)
    }
  
    const getDetails = async (e, row) => {
      if (costElementsRemaining.length > 0 && e.target.innerHTML !== "") {
        row.original.mappingType = mappingType;
        row.original.year = yearFilter;
        row.original.month = monthFilter;
        row.original.mapping = 'costpool';
        // console.log('row',row.original)
        let [err, data] = await fetchRequest.post(`/api/dataflow/getGLData/`, JSON.stringify(row.original));
        if (err) {
          console.error('err', err)
          // handleError(err);
        }
        else {
          let newData
            newData = data.map(item => {
              return {
                "accountCode": item["account.code"],
                "accountDescription": item["account.description"],
                "expenseType": item["expenseType.name"],
                "costCentreCode": item["costCentre.code"],
                "costCentreDescription": item["costCentre.description"],
                "vendorCode": item["vendor.code"],
                "vendorName": item["vendor.name"],
                "rgtModel": item["rgtModel"],
                "costType": item["costType"],
                "amount": item["amount"],
                "applicationID": item["applicationID"],
                "invoice": item["invoice"],
                "journalID": item["journalID"],
                "journalLine": item["journalLine"],
                "projectID": item["projectID"],
                "transactionDate": item["transactionDate"]
              }
            });
            setGLRows(newData)
            setDialogOpen(true)
        }
      }
    };
  
    const saveRules = async (evt) => {
      evt.preventDefault();
      let bgData = await backgroundProcessCheck(monthFilter, yearFilter);
      if (bgData.length > 0) {
        showAlert(bgData[0], bgData[1], bgData[2], bgData[3]);
      }
      else {
        let err = false;
        let e = {};
        if (selectedRows.length === 0) {
          e.selectedRows = true;
          err = true;
        }
        if (costPool === "") {
          e.costPool = true;
          err = true;
        }
        if (!err) {
          let ceSelected = selectedRows.map((i) => {
            return { ...costElementsRemaining[i], "costPoolId": costPool.split(" | ")[0], "subCostPoolId": costPool.split(" | ")[1], "id": null, "yearNameId": yearFilter, "monthNameId": monthFilter, "source": 'GL' }
          })
          setIsSubmitting(true);
          let [error, data1] = await fetchRequest.post(`/api/dataflow/costPoolRules/${yearFilter}/${monthFilter}`, JSON.stringify(ceSelected))
          if (error) {
            if (error.data?.message) {
              showSnackbar(error.data?.message, "error")
            } else {
              showSnackbar("An error occured while processing your request.", "error");
            }
          }
          else if (data1) {
            showSnackbar(data1, "success");
            if (props.setRefresh) {
              props.setRefresh(Math.random());
            }
            reloadData();
          }
          setIsSubmitting(false);
        } else {
          setErrors(e);
        }
      }
    }
  
    if (mappingType === 1) {
      costElementsRemaining = data.costElements.map((a, i) => { return ({ id: i, expenseTypeId: a["expenseType.id"], ExpenseType: a["expenseType.name"], accountId: a["account.id"], AccountCode: a["account.code"], AccountDescription: a["account.description"], amount: a.amount }) })
    }
    if (mappingType === 2) {
      costElementsRemaining = data.costElements.map((a, i) => { return ({ id: i, expenseTypeId: a["expenseType.id"], ExpenseType: a["expenseType.name"], accountId: a["account.id"], AccountCode: a["account.code"], AccountDescription: a["account.description"], costCentreId: a["costCentre.id"], CostCentreCode: a["costCentre.code"], CostCentreDescription: a["costCentre.description"], amount: a.amount }) })
    }
    if (mappingType === 3) {
      costElementsRemaining = data.costElements.map((a, i) => { return ({ id: i, expenseTypeId: a["expenseType.id"], ExpenseType: a["expenseType.name"], accountId: a["account.id"], AccountCode: a["account.code"], AccountDescription: a["account.description"], costCentreId: a["costCentre.id"], CostCentreCode: a["costCentre.code"], CostCentreDescription: a["costCentre.description"], vendorId: a["vendor.id"], VendorCode: a["vendor.code"], VendorName: a["vendor.name"], amount: a.amount }) })
    }
  
    const amountAllocated = selectedRows?.reduce((total, idx) => total + costElementsRemaining[idx]?.amount, 0);
  
    let columns = []
    if (mappingType === 1) {
      columns = [
        { Header: "Account Code", accessor: "AccountCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Description", accessor: "AccountDescription", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Expense Type", accessor: "ExpenseType", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Amount", accessor: "amount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>}, 
        },
      ]
    }
    if (mappingType === 2) {
      columns = [
        { Header: "Account Code", accessor: "AccountCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Description", accessor: "AccountDescription", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Expense Type", accessor: "ExpenseType", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Code", accessor: "CostCentreCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Name", accessor: "CostCentreDescription", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Amount", accessor: "amount", align: "right", dataType: "currency", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>},
        },
      ]
    }
    if (mappingType === 3) {
      columns = [
        { Header: "Account Code", accessor: "AccountCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Description", accessor: "AccountDescription", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Expense Type", accessor: "ExpenseType", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Code", accessor: "CostCentreCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Name", accessor: "CostCentreDescription", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Code", accessor: "VendorCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Name", accessor: "VendorName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Amount", accessor: "amount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => {return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography>}, 
        },
      ]
    }
  
    const columnsMemo = useMemo(() => columns, [yearFilter, monthFilter, mappingType]);
    const rowsMemo = useMemo(() => costElementsRemaining, [yearFilter, monthFilter, mappingType, refresh]);
  
    const filteredMappingTypes = mappingTypes.filter(m => m.value !== mappingType);
  
    const strategyItemStyles = () => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      pl: 2,
      pr: 1,
      py: 1.5,
      zIndex: 2,
      marginBottom: "0px",
      marginRight: "-10px",
      cursor: "pointer",
      borderRadius: "10px",
      border: "1px solid #ddd",
      "& .selectionBox": {
        display: "flex",
        flexDirection: "column",
      },
      "& .title": {
        marginBottom: 1
      }
    });
  
    let glColumns = [
      { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Account Description", accessor: "accountDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Center Name", accessor: "costCentreDescription", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> } },
      { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
      { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
      { Header: "Invoice Number", accessor: "invoice", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
    ]
  
    if (costElementsRemaining.length > 0) {
      return (
        <>
          <Modal open={dialogOpen} onClose={handleDialogOpen}>
            <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
              <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <MDBox>
                    <MDTypography variant="h6" component="span" color="text">
                      General Ledger Transactions
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex">
                    <IconButton onClick={handleDialogOpen} title="Close">
                      <Icon>close</Icon>
                    </IconButton>
                  </MDBox>
                </MDBox>
                <DataTable
                  variant="tile"
                  table={{ columns: glColumns, rows: glRows }}
                  containerMaxHeight={424}
                  showTotalEntries={true}
                  isSorted={true}
                  newStyle1={true}
                  noEndBorder
                  entriesPerPage={true}
                  canSearch={true}
  
                >
                </DataTable>
              </Card>
            </MDBox>
          </Modal>
  
          <MDBox display="flex">
            <MDBox width={selectedRows?.length > 0 ? "60%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
              <MDBox pl={3} pr={4} pt={1} display="flex" alignItems="center" justifyContent="space-between">
                <MDTypography variant="subtitle1" fontWeight="medium" color="dark" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">Select one or more accounts to create a rule.</MDTypography>
                {
                  selectedRows?.length === 0 &&
                  <MDBox
                    sx={(theme) => strategyItemStyles(theme)}
                    onClick={handleOnOptionsClick}
                  >
                    <MDBox className="selectionBox">
                      <MDTypography className="title" variant="caption" color="text">Map by</MDTypography>
                      <MDTypography className="subtitle" variant="caption" fontWeight="medium" color="text">{selectedMappingTypeOption?.displayName}</MDTypography>
                    </MDBox>
                    <Icon sx={{ ml: 1.5, mt: .5, fontSize: "32px!important", color: "rgba(0, 0, 0, 0.54)" }}>keyboard_arrow_down</Icon>
                  </MDBox>
                }
              </MDBox>
              <DataTable
                variant="tile"
                table={{ columns: columnsMemo, rows: rowsMemo }}
                showTotalEntries={true}
                isSorted={true}
                containerMaxHeight={containerHeight}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                isSelectable={!chargeBack}
                onUpdate={handleOnUpdate}
                onRowClick={getDetails}
                onSelectionClearClick={handleOnSelectionClearClick}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
              >
              </DataTable>
            </MDBox>
            {
              selectedRows?.length > 0 && (
                <MDBox width="40%" px={3} pt={4} pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <MDBox flex={1} textAlign="center" display="flex">
                    <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
                      <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
                      <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(normalizeCurrency(amountAllocated)).format('$0,0')}</MDTypography>
                    </MDBox>
                    {errors.selectedRows && <MDTypography variant="caption" color="error">Please select an account</MDTypography>}
                  </MDBox>
                  <MDBox>
                    <Icon sx={{ mt: 3, mb: 1, color: "#7b809a", fontSize: "48px!important" }}>south</Icon>
                  </MDBox>
                  <MDBox flex={1} textAlign="center">
                    <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.costPool ? "error" : "dark"}>Choose a Cost Pool *</MDTypography>
                    <TextField error={errors.costPool} name="costPool" select margin="normal" variant="outlined" sx={{ minWidth: 300 }} onChange={(e) => { setErrors({}); setCostPool(e.target.value) }} value={costPool} required={true}>
                      {data.subCostPools.map((item) => <MenuItem key={`${item.costPoolId} | ${item.id}`} value={`${item.costPoolId} | ${item.id}`}>{item["costPool.name"]} | {item["name"]}</MenuItem>)}
                    </TextField>
                  </MDBox>
                  {
                    costPool && costPool !== "" && (
                      <MDBox px={3} pb={3} display="flex" alignItems="center" justifyContent="center">
                        <MDButton name="saveRules" variant="gradient" color="info" sx={{ mt: 2 }}
                          disabled={isSubmitting}
                          startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined}
                          onClick={saveRules}>Save Rules</MDButton>
                      </MDBox>
                    )
                  }
                </MDBox>
              )
            }
            {showOptions && (
              <StrategySelectionSidebar
                options={filteredMappingTypes}
                onOptionSelection={handleOnOptionSelection}
                onOptionsClose={handleOnOptionsClose}
              />
            )}
          </MDBox>
        </>
      )
    } else if (costElementsRemaining.length === 0 && data.noOfItems > 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br/><br/>All accounts have been mapped successfully.</MDTypography>
      </MDBox>
      );
    } else if (data.noOfItems === 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br/><br/>Actuals are not loaded.</MDTypography>
      </MDBox>
      );
    }
  }
  else
  {
    const getDetails = async (e, row) => {
      if (costElementsRemaining.length > 0 && e.target.innerHTML !== "") {
        row.original.mappingType = mappingType;
        row.original.year = yearFilter;
        row.original.month = monthFilter;
        row.original.mapping = 'costpool';
        // console.log('row',row.original)
        let [err, data] = await fetchRequest.post(`/api/dataflow/getGLDataBudget/`, JSON.stringify(row.original));
        if (err) {
          console.error('err', err)
          // handleError(err);
        }
        else {
          let newData
          
          newData = data.map(item => {
            return {
              "accountCode": item["account.code"],
              "accountDescription": item["account.description"],
              "expenseType": item["expenseType.name"],
              "costCentreCode": item["costCentre.code"],
              "costCentreDescription": item["costCentre.description"],
              "vendorCode": item["vendor.code"],
              "vendorName": item["vendor.name"],
              "amount": item["amount"]
            }
          });
          setGLRows(newData)
          setDialogOpen(true)}
      }
    };
  
    
    if (mappingType === 3) {
      costElementsRemaining = data.costElements.map((a, i) => { return ({ id: i, expenseTypeId: a["expenseType.id"], ExpenseType: a["expenseType.name"], accountId: a["account.id"], AccountCode: a["account.code"], AccountDescription: a["account.description"], costCentreId: a["costCentre.id"], CostCentreCode: a["costCentre.code"], CostCentreDescription: a["costCentre.description"], vendorId: a["vendor.id"], VendorCode: a["vendor.code"], VendorName: a["vendor.name"], amount: a.amount }) })
    }
  
    const amountAllocated = selectedRows?.reduce((total, idx) => total + costElementsRemaining[idx]?.amount, 0);
  
    let columns = []
    if (mappingType === 3) {
      columns = [
        { Header: "Account Code", accessor: "AccountCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Description", accessor: "AccountDescription", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Expense Type", accessor: "ExpenseType", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Code", accessor: "CostCentreCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Name", accessor: "CostCentreDescription", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Code", accessor: "VendorCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Name", accessor: "VendorName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Budget", accessor: "amount", align: "right", dataType: "currency", disableFilters: false,  Cell: ({ cell: { value } }) => {return <MDTypography variant="caption" color="dark">{numeral(value.toFixed(2)).format("$0,0")}</MDTypography>} }, 
      ]
    }
  
    const columnsMemo = useMemo(() => columns, [yearFilter, monthFilter, mappingType]);
    const rowsMemo = useMemo(() => costElementsRemaining, [yearFilter, monthFilter, mappingType, refresh]);
  
    // const filteredMappingTypes = mappingTypes.filter(m => m.value !== mappingType);
  
    // const strategyItemStyles = () => ({
    //   display: "flex",
    //   flexDirection: "row",
    //   alignItems: "center",
    //   pl: 2,
    //   pr: 1,
    //   py: 1.5,
    //   zIndex: 2,
    //   marginBottom: "0px",
    //   marginRight: "-10px",
    //   cursor: "pointer",
    //   borderRadius: "10px",
    //   border: "1px solid #ddd",
    //   "& .selectionBox": {
    //     display: "flex",
    //     flexDirection: "column",
    //   },
    //   "& .title": {
    //     marginBottom: 1
    //   }
    // });
  
    if (costElementsRemaining.length > 0) {
      return (
        <>
  
          <MDBox display="flex">
            <MDBox width={selectedRows?.length > 0 ? "60%" : "100%"} borderRight="1px solid rgba(0, 0, 0, 0.05)">
              <DataTable
                variant="tile"
                table={{ columns: columnsMemo, rows: rowsMemo }}
                showTotalEntries={true}
                isSorted={true}
                containerMaxHeight={containerHeight}
                newStyle1={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                isSelectable={false}
                onRowClick={getDetails}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
              >
              </DataTable>
            </MDBox>
            {
              selectedRows?.length > 0 && (
                <MDBox width="40%" px={3} pt={4} pb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <MDBox flex={1} textAlign="center" display="flex">
                    <MDBox display="flex" flexDirection="column" flex={1} mt="auto">
                      <MDTypography variant="button" component="span" fontWeight="medium" color="text">Allocating</MDTypography>
                      <MDTypography variant="h3" component="span" fontWeight="medium" color="dark">{numeral(amountAllocated.toFixed(2)).format('$0,0')}</MDTypography>
                    </MDBox>
                    {errors.selectedRows && <MDTypography variant="caption" color="error">Please select an account</MDTypography>}
                  </MDBox>
                  <MDBox>
                    <Icon sx={{ mt: 3, mb: 1, color: "#7b809a", fontSize: "48px!important" }}>south</Icon>
                  </MDBox>
                  <MDBox flex={1} textAlign="center">
                    <MDTypography variant="subtitle2" fontWeight="medium" color={errors?.costPool ? "error" : "dark"}>Choose a Cost Pool *</MDTypography>
                    <TextField error={errors.costPool} name="costPool" select margin="normal" variant="outlined" sx={{ minWidth: 300 }} onChange={(e) => { setErrors({}); setCostPool(e.target.value) }} value={costPool} required={true}>
                      {data.subCostPools.map((item) => <MenuItem key={`${item.costPoolId} | ${item.id}`} value={`${item.costPoolId} | ${item.id}`}>{item["costPool.name"]} | {item["name"]}</MenuItem>)}
                    </TextField>
                  </MDBox>
                </MDBox>
              )
            }
          </MDBox>
        </>
      )
    } else if (costElementsRemaining.length === 0 && data.noOfItems > 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br/><br/>All accounts have been mapped successfully.</MDTypography>
      </MDBox>
      );
    } else if (data.noOfItems === 0) {
      return (<MDBox height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <MDTypography variant="subtitle1" fontWeight="medium" color="text"><br/><br/>Actuals are not loaded.</MDTypography>
      </MDBox>
      );
    }
  }
  
}

export default AddCostPoolRule;